import { Component, Inject, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, CommonModule } from '@angular/common';
import { CommonService } from '../../../services/common.service';
import { AuthService } from '../../../services/auth.service';
import {
  catchError,
  filter,
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  tap,
} from 'rxjs';
import { MatChipsModule } from '@angular/material/chips';
import {
  FormConstants,
  validNameValidator,
  validPriceValidator,
} from '../../../constants/patterns';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import moment from 'moment';
import { SharedDataService } from '../../../services/shared-data.service';

interface DialogData {
  menuItem: any;
  vendor: any;
}

@Component({
  selector: 'dialog-add-menu-items',
  templateUrl: 'dialog-add-menu-items.html',
  styleUrls: [],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    AsyncPipe,
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDatepickerModule,
  ],
  providers: [],
})
export class DialogAddMenuItems {
  public readonly title: string = 'Add menu item';
  public readonly btnText: string = 'Save';
  public panelOpenState: boolean = false;
  public readonly daysOfWeek = [
    { short: 'Sun', long: 'Sunday' },
    { short: 'Mon', long: 'Monday' },
    { short: 'Tue', long: 'Tuesday' },
    { short: 'Wed', long: 'Wednesday' },
    { short: 'Thu', long: 'Thursday' },
    { short: 'Fri', long: 'Friday' },
    { short: 'Sat', long: 'Saturday' },
  ];
  public submitted: boolean = false;
  public req$: Observable<any> = of([]);
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  private readonly shareDataService: SharedDataService =
    inject(SharedDataService);
  public form: FormGroup;
  public categories: any[] = [];
  public subCategories: any[] = [];
  public displaySubCategories: any[] = [];
  public menuGroups: any[] = [];
  public menuGroupsNonUnique: any[] = [];
  public branches: any[] = [];

  constructor(
    public readonly dialogRef: MatDialogRef<DialogAddMenuItems>,
    private readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {
    console.log('Data received for confirm dialog : ', this.data);
    this.form = this._buildForm();
    this.commonService.showLoader();
    this.req$ = forkJoin({
      cat: this._getCat(),
      sub_cat: this._getSubCat(),
      group: this._getModifierGroup(),
      branches: this._getBranches(),
      branchMenuItem: this._getMenuItemDetailsFromBranch(),
    });
    this.req$ = this.req$.pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$.pipe(
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          'Error; Failed to validate menu item!'
        )
      ),
      filter((e1: any) => !!e1),
      tap((d: any) => {
        if (this.data.menuItem) {
          // only for edit
          console.log('value of d : ', d);
          const { branchMenuItem = {} } = d || {};
          console.log('Branch menu : ', branchMenuItem);
          const { branchMenu = [] } = branchMenuItem;
          if (!this.form) this.form = this._buildForm();
          const {
            name,
            cat_id,
            sub_cat: sub_cat_id,
            price,
          } = this.data.menuItem;
          const cat = this.categories.find((e1) => e1.id == cat_id);
          console.log('sub categories : ', this.subCategories);
          this.displaySubCategories = this.subCategories.filter(
            (e1) => e1.cat_id == cat_id
          );
          const sub_cat = this.displaySubCategories.find(
            (e1) => e1.id == sub_cat_id
          );
          console.log('sub category found : ', sub_cat);
          this.f['name'].setValue(name);
          this.f['price'].setValue(price + '');
          this.f['cat'].setValue(cat || {});
          this.f['sub_cat'].setValue(sub_cat || {});
          const { pm = [], rm = [], mm = [], pmm = [] } = this.data.menuItem;
          console.log('Modifiers : ', { pm, rm, mm, pmm });
          for (const p of pmm) {
            const { group_id = '', items = [] } = p;
            if (!group_id) continue;
            const type = 'pmm';
            this.addModifierGroup(type, null, p, true);
            const index = this.pmm.length - 1;
            for (const item of items) {
              this.addModifierItem(index, type, null, item);
            }
          }

          for (const p of pm) {
            const { group_id = '', items = [] } = p;
            if (!group_id) continue;
            const type = 'pm';
            this.addModifierGroup(type, null, p, true);
            const index = this.pm.length - 1;
            for (const item of items) {
              this.addModifierItem(index, type, null, item);
            }
          }

          for (const p of rm) {
            const { group_id = '', items = [] } = p;
            if (!group_id) continue;
            const type = 'rm';
            this.addModifierGroup(type, null, p, true);
            const index = this.rm.length - 1;
            for (const item of items) {
              this.addModifierItem(index, type, null, item);
            }
          }

          for (const p of mm) {
            const { group_id = '', items = [] } = p;
            if (!group_id) continue;
            const type = 'mm';
            this.addModifierGroup(type, null, p, true);
            const index = this.mm.length - 1;
            for (const item of items) {
              this.addModifierItem(index, type, null, item);
            }
          }

          for (const b of branchMenu) {
            console.log('Each branch menu : ', b);
            const { branch = {} } = b;
            console.log('Branch is : ', branch);
            this.branches.push({ ...branch });
            this._addBranch(b);
          }
          console.log('Branch arrau is : ', this.branches);
        }
      })
    );
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  private _buildForm() {
    let form = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          validNameValidator(),
          Validators.maxLength(FormConstants.MAX_CHAR),
        ],
      ],
      price: ['', [Validators.required, validPriceValidator()]],
      cat: ['', [Validators.required]],
      sub_cat: ['', [Validators.required]],
      is_disabled: [false],
      pm: this.fb.array([]),
      rm: this.fb.array([]),
      mm: this.fb.array([]),
      pmm: this.fb.array([]),
      ava_branches: this.fb.array([]),
    });

    return form;
  }

  public get f() {
    return this.form.controls;
  }

  private _validateModifiers() {
    const pm = [];
    for (const a of this.pm.controls) {
      const group = a.get('group_name')?.value;
      const { id: group_id } = group || {};
      const group_items = a.get('group_items')?.value as any[];
      console.log('Group items : ', group_items);
      const items = [];
      for (const g of group_items) {
        const { name, unit_price: price, id } = g;
        items.push({ name, price, id });
      }
      const obj = { group_id, items };
      pm.push(obj);
    }
    console.log('pm value is : ', pm);

    const pmm = [];
    for (const a of this.pmm.controls) {
      const group = a.get('group_name')?.value;
      const { id: group_id } = group || {};
      const group_items = a.get('group_items')?.value as any[];
      console.log('Group items : ', group_items);
      const items = [];
      for (const g of group_items) {
        const { name, unit_price: price, id } = g;
        items.push({ name, price, id });
      }
      const obj = { group_id, items };
      pmm.push(obj);
    }
    console.log('pmm value is : ', pmm);

    const rm = [];
    for (const a of this.rm.controls) {
      const group = a.get('group_name')?.value;
      const { id: group_id } = group || {};
      const group_items = a.get('group_items')?.value as any[];
      console.log('Group items : ', group_items);
      const items = [];
      for (const g of group_items) {
        const { name, id } = g;
        items.push({ name, price: 0, id });
      }
      const obj = { group_id, items };
      rm.push(obj);
    }
    console.log('rm value is : ', rm);

    const mm = [];
    for (const a of this.mm.controls) {
      const group = a.get('group_name')?.value;
      const { id: group_id } = group || {};
      const group_items = a.get('group_items')?.value as any[];
      console.log('Group items : ', group_items);
      const items = [];
      for (const g of group_items) {
        const { name, id } = g;
        items.push({ name, price: 0, id });
      }
      const obj = { group_id, items };
      mm.push(obj);
    }
    console.log('mm value is : ', mm);
    return { pm, pmm, rm, mm };
  }

  private _validateBranches() {
    const branches = [];
    for (const a of this.ava_branches.controls) {
      const id = a.get('branch_id')?.value;
      let price = a.get('price')?.value;
      console.log('Branch price : ', price);
      if (typeof price != 'number') {
        price = this.f['price'].value;
      }
      const is_disabled =
        this.f['is_disabled'].value || a.get('is_disabled')?.value;
      const ava_on = a.get('ava_on')?.value as any[];
      console.log('ava on : ', ava_on);

      const ava_between = a.get('ava_between')?.value as any[];
      const ava_between_validated = ava_between
        .filter(
          (e1) => e1.start_date || e1.end_date || e1.start_time || e1.end_time
        )
        .map((e1) => {
          let { start_time, start_date, end_time, end_date } = e1;
          const FORMAT = 'YYYY-MM-DD';
          let oj: any = { start_time, end_time, start_date, end_date };
          if (moment.isMoment(start_date)) {
            console.log('Instance of moment');
            start_date = start_date.format(FORMAT);
            oj = { ...oj, start_date };
          }

          if (moment.isMoment(end_date)) {
            console.log('end date Instance of moment');
            end_date = end_date.format(FORMAT);
            oj = { ...oj, end_date };
          }
          return oj;
        });
      console.log('ava between : ', ava_between);
      console.log('ava between validated : ', ava_between_validated);
      branches.push({
        id,
        price,
        is_disabled,
        ava_on,
        ava_between: ava_between_validated,
      });
    }
    return branches;
  }

  public onSaveClick() {
    this.submitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.commonService.showSnackbar('Error; Invalid form!');
      return;
    }
    const url = '/api/cc-backbone/menu-items/abs';
    const keys = ['name', 'is_disabled', 'price', 'cat', 'sub_cat'];
    let menuItem: any = {};
    let invalid: boolean = false;
    for (const k of keys) {
      let value = this.f[k].value;
      console.log('value of k is : ', k);
      console.log('value of form is : ', value);
      if (typeof value == 'string') {
        value = value.trim();
      }

      if (k == 'name') {
        if (!value) {
          this.f[k].setErrors({ required: true });
          invalid = true;
        }
      }

      if (k == 'price') {
        if (!value) {
          this.f[k].setErrors({ required: true });
          invalid = true;
        }
        value = Number(value);
      }

      if (k == 'cat') {
        if (value) {
          value = value.id;
        }
        if (!value) {
          this.f[k].setErrors({ required: true });
          invalid = true;
        }
      }

      if (k == 'sub_cat') {
        if (value) {
          const cat = this.f['cat'].value;
          if (cat && value.cat_id == cat.id) {
            value = value.id;
          } else {
            this.f['cat'].setErrors({ required: true });
            invalid = true;
          }
        }
        if (!value) {
          this.f[k].setErrors({ required: true });
          invalid = true;
        }
      }
      menuItem[k] = value;
    }
    console.log('loop completed');
    // validate modifiers
    try {
      const { pm, pmm, rm, mm } = this._validateModifiers();
      menuItem = { ...menuItem, pm, rm, mm, pmm };
    } catch (error) {
      console.log('Error on validate modifier: ', error);
      invalid = true;
    }

    try {
      const branches = this._validateBranches();
      menuItem = { ...menuItem, branches };
    } catch (error) {
      console.log('Error on validate branch: ', error);
      invalid = true;
    }

    console.log('Menu item obj : ', menuItem);

    if (invalid) {
      this.commonService.showSnackbar('Error; Invalid form');
      return;
    }
    const obj: any = {};
    obj['vendor_id'] = this.data.vendor.id;
    obj['id'] = this.data.menuItem ? this.data.menuItem.id || null : null;
    obj['shardingId'] = this.data.menuItem
      ? this.data.menuItem.shardingId || null
      : null;
    obj['item'] = menuItem;
    this.commonService.showLoader();
    this.req$ = this.authService.postData(url, obj).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          this.data.menuItem
            ? 'Error; Failed to update menu item'
            : 'Error; Failed to create menu item'
        )
      ),
      filter((e: any) => !!e),
      tap((d) => {
        console.log('Response from backbone : ', d);
        const { msg, data } = d;
        const { menu_item } = data;
        console.log('Message after menu item : ', msg);
        console.log('Menu item from backbone is : ', menu_item);
        this.commonService.showSnackbar(msg);
        this.dialogRef.close({ ...menu_item });
      })
    );
  }

  public get pm(): FormArray {
    return this.form.get('pm') as FormArray;
  }

  public getPmItemsArray(index: number): FormArray {
    return this.pm.at(index).get('group_items') as FormArray;
  }

  public get pmm(): FormArray {
    return this.form.get('pmm') as FormArray;
  }

  public getPmmItemsArray(index: number): FormArray {
    return this.pmm.at(index).get('group_items') as FormArray;
  }

  public get rm(): FormArray {
    return this.form.get('rm') as FormArray;
  }

  public getRmItemsArray(index: number): FormArray {
    return this.rm.at(index).get('group_items') as FormArray;
  }

  public get mm(): FormArray {
    return this.form.get('mm') as FormArray;
  }

  public getMmItemsArray(index: number): FormArray {
    return this.mm.at(index).get('group_items') as FormArray;
  }

  public addModifierGroup(
    type: string,
    form: any = null,
    value: any = null,
    edit: boolean = false
  ) {
    console.log('form is : ', form);
    if (form) {
      form.markAllAsTouched();
      if (form.invalid) {
        return;
      }
    }
    let dateRange = this.fb.group({
      group_name: [
        '',
        [Validators.required, Validators.maxLength(FormConstants.MAX_CHAR)],
      ],
      group_items: this.fb.array([]),
    });
    if (value && edit) {
      const { group_id = '' } = value;
      const group = this.menuGroups.find((e1) => group_id && e1.id == group_id);
      console.log('Modifier group from modifier array : ', group);
      dateRange = this.fb.group({
        group_name: [
          group || '',
          [Validators.required, Validators.maxLength(FormConstants.MAX_CHAR)],
        ],
        group_items: this.fb.array([]),
      });
    }
    if (type == 'pm') {
      this.pm.push(dateRange);
      if (!edit) {
        const timeIndex = this.pm.length - 1;
        this.addModifierItem(timeIndex, type, value);
      }
    } else if (type == 'rm') {
      this.rm.push(dateRange);
      if (!edit) {
        const timeIndex = this.rm.length - 1;
        this.addModifierItem(timeIndex, type, value);
      }
    } else if (type == 'pmm') {
      this.pmm.push(dateRange);
      if (!edit) {
        const timeIndex = this.pmm.length - 1;
        this.addModifierItem(timeIndex, type, value);
      }
    } else {
      this.mm.push(dateRange);
      if (!edit) {
        const timeIndex = this.mm.length - 1;
        this.addModifierItem(timeIndex, type, value);
      }
    }
  }

  public removeModiferGroup(i: number, type: string) {
    if (type == 'pm') {
      const start_date = this.pm.at(i).get('group_name')?.value;
      if (start_date) {
        const con = confirm('You want to remove?');
        if (con) {
          this.pm.removeAt(i);
          return;
        } else return;
      }
      const ava_times = this.getPmItemsArray(i);
      let con = false;
      for (const a of ava_times.controls) {
        const start_time = a.get('name')?.value;
        const end_time = a.get('unit_price')?.value;
        con = start_time || end_time;
        if (con) break;
      }
      if (con) {
        const con = confirm('You want to remove?');
        if (!con) return;
      }
      this.pm.removeAt(i);
    } else if (type == 'rm') {
      const start_date = this.rm.at(i).get('group_name')?.value;
      if (start_date) {
        const con = confirm('You want to remove?');
        if (con) {
          this.rm.removeAt(i);
          return;
        } else return;
      }
      const ava_times = this.getRmItemsArray(i);
      let con = false;
      for (const a of ava_times.controls) {
        const start_time = a.get('name')?.value;
        const end_time = a.get('unit_price')?.value;
        con = start_time || end_time;
        if (con) break;
      }
      if (con) {
        const con = confirm('You want to remove?');
        if (!con) return;
      }
      this.rm.removeAt(i);
    } else if (type == 'pmm') {
      const start_date = this.pmm.at(i).get('group_name')?.value;
      if (start_date) {
        const con = confirm('You want to remove?');
        if (con) {
          this.pmm.removeAt(i);
          return;
        } else return;
      }
      const ava_times = this.getPmmItemsArray(i);
      let con = false;
      for (const a of ava_times.controls) {
        const start_time = a.get('name')?.value;
        const end_time = a.get('unit_price')?.value;
        con = start_time || end_time;
        if (con) break;
      }
      if (con) {
        const con = confirm('You want to remove?');
        if (!con) return;
      }
      this.pmm.removeAt(i);
    } else {
      const start_date = this.mm.at(i).get('group_name')?.value;
      if (start_date) {
        const con = confirm('You want to remove?');
        if (con) {
          this.mm.removeAt(i);
          return;
        } else return;
      }
      const ava_times = this.getMmItemsArray(i);
      let con = false;
      for (const a of ava_times.controls) {
        const start_time = a.get('name')?.value;
        const end_time = a.get('unit_price')?.value;
        con = start_time || end_time;
        if (con) break;
      }
      if (con) {
        const con = confirm('You want to remove?');
        if (!con) return;
      }
      this.mm.removeAt(i);
    }
  }

  public addModifierItem(
    dateRangeIndex: number,
    type: string,
    form: any = null,
    value: any = null
  ) {
    const disabled = type == 'mm' || type == 'rm';
    let timeRange = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(FormConstants.MAX_CHAR),
          validNameValidator(),
        ],
      ],
      unit_price: [
        { value: '', disabled },
        [Validators.required, Validators.min(0)],
      ],
      id: [''],
    });

    if (value) {
      const { name = '', price = null, id = '' } = value;
      timeRange = this.fb.group({
        name: [
          name,
          [
            Validators.required,
            Validators.maxLength(FormConstants.MAX_CHAR),
            validNameValidator(),
          ],
        ],
        unit_price: [
          { value: price, disabled },
          [Validators.required, Validators.min(0)],
        ],
        id: [id],
      });
    }

    console.log('form is : ', form);
    if (form) {
      form.markAllAsTouched();
      if (form.invalid) {
        return;
      }
    }

    if (type == 'pm') {
      this.getPmItemsArray(dateRangeIndex).push(timeRange);
    } else if (type == 'rm') {
      this.getRmItemsArray(dateRangeIndex).push(timeRange);
    } else if (type == 'pmm') {
      this.getPmmItemsArray(dateRangeIndex).push(timeRange);
    } else {
      this.getMmItemsArray(dateRangeIndex).push(timeRange);
    }
  }

  public removeModifierItem(type: string, i: number, j: number) {
    if (j == 0) return;
    if (type == 'pmm') {
      const name = this.getPmmItemsArray(i).controls.at(j)?.get('name')?.value;
      const price = this.getPmmItemsArray(i)
        .controls.at(j)
        ?.get('unit_price')?.value;
      if (name || price) {
        const con = confirm('You want to close?');
        if (!con) return;
      }
      this.getPmmItemsArray(i).removeAt(j);
    } else if (type == 'pm') {
      const name = this.getPmItemsArray(i).controls.at(j)?.get('name')?.value;
      const price = this.getPmItemsArray(i)
        .controls.at(j)
        ?.get('unit_price')?.value;
      if (name || price) {
        const con = confirm('You want to close?');
        if (!con) return;
      }
      this.getPmItemsArray(i).removeAt(j);
    } else if (type == 'rm') {
      const name = this.getRmItemsArray(i).controls.at(j)?.get('name')?.value;
      const price = this.getRmItemsArray(i)
        .controls.at(j)
        ?.get('unit_price')?.value;
      if (name || price) {
        const con = confirm('You want to close?');
        if (!con) return;
      }
      this.getRmItemsArray(i).removeAt(j);
    } else {
      const name = this.getMmItemsArray(i).controls.at(j)?.get('name')?.value;
      const price = this.getMmItemsArray(i)
        .controls.at(j)
        ?.get('unit_price')?.value;
      if (name || price) {
        const con = confirm('You want to close?');
        if (!con) return;
      }
      this.getMmItemsArray(i).removeAt(j);
    }
  }

  private _getModifierGroup() {
    const { id } = this.data.vendor;
    const url = '/api/cc-backbone/menu-group?vendor_id=' + id;
    return this.authService.getData(url).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          'Error; Failed to get modifier groups'
        )
      ),
      filter((d: any) => d && true),
      map((d) => d.data),
      tap((d) => {
        const { items = [] } = d;
        this.menuGroups = items;
        this.menuGroupsNonUnique = items;
      })
    );
  }

  private _getCat() {
    const { id } = this.data.vendor;
    const url = '/api/cc-backbone/category?vendor_id=' + id;
    return this.authService.getData(url).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          'Error; Failed to get modifier groups'
        )
      ),
      filter((d: any) => d && true),
      map((d) => d.data),
      tap((d) => {
        const { items = [] } = d;
        this.categories = items;
      })
    );
  }

  private _getSubCat() {
    const { id } = this.data.vendor;
    const url = '/api/cc-backbone/sub-cat?vendor_id=' + id;
    return this.authService.getData(url).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          'Error; Failed to get modifier groups'
        )
      ),
      filter((d: any) => d && true),
      map((d) => d.data),
      tap((d) => {
        const { items = [] } = d;
        this.subCategories = items;
      })
    );
  }

  public onClearClickOfModifier(type: string) {
    if (type == 'pmm') {
      if (this.pmm.length == 0) return;
      const con = confirm('You want to clear?');
      if (!con) return;
      while (this.pmm.length > 0) {
        this.pmm.removeAt(0);
      }
    } else if (type == 'pm') {
      if (this.pm.length == 0) return;
      const con = confirm('You want to clear?');
      if (!con) return;
      while (this.pm.length > 0) {
        this.pm.removeAt(0);
      }
    } else if (type == 'rm') {
      if (this.rm.length == 0) return;
      const con = confirm('You want to clear?');
      if (!con) return;
      while (this.rm.length > 0) {
        this.rm.removeAt(0);
      }
    } else {
      if (this.mm.length == 0) return;
      const con = confirm('You want to clear?');
      if (!con) return;
      while (this.mm.length > 0) {
        this.mm.removeAt(0);
      }
    }
  }

  public onSelectCat(ev: MatSelectChange) {
    console.log('on cat select : ', ev);
    const { id } = ev.value;
    console.log('cat id : ', id);
    console.log('sub cat ; ', this.subCategories);
    const key = 'sub_cat';
    this.f[key].reset();
    this.f[key].markAsTouched();
    this.displaySubCategories = this.subCategories.filter(
      ({ cat_id }) => cat_id == id
    );
  }

  private _getBranches() {
    if (this.data.menuItem) return of([]);
    const { id } = this.data.vendor;
    return this.shareDataService
      .getBranches({ vendor_id: id, include_all: false })
      .pipe(
        tap((d) => {
          this.branches = d;
          if (!this.data.menuItem) {
            for (const a of this.branches) {
              this._addBranch(a);
            }
          }
        })
      );
  }

  public get ava_branches(): FormArray {
    return this.form.get('ava_branches') as FormArray;
  }

  public getAvaBetweenArray(branchIndex: number): FormArray {
    return this.ava_branches.at(branchIndex).get('ava_between') as FormArray;
  }

  private _addBranch(branchObj: any) {
    console.log('Add branch called with obj : ', branchObj);
    let {
      name,
      id,
      is_disabled = false,
      ava_on = [],
      ava_between = [],
    } = branchObj;
    let price: any = 0;
    if (this.data.menuItem) {
      const { branch = {}, price: priceIn = null } = branchObj;
      id = branch.id || '';
      name = branch.name || '';
      price = priceIn;
    } else {
      price = this.f['price'].value;
      price = Number(price);
      if (!price) {
        price = null;
      }
    }
    console.log('name and id of branch is : ', { name, id });
    const branch = this.fb.group({
      branch_id: [id, Validators.required],
      branch_name: [name, Validators.required],
      price: [price, [Validators.min(0)]],
      is_disabled: [is_disabled],
      ava_between: this.fb.array([]),
      ava_on: this.fb.array([]),
    });
    this.ava_branches.push(branch);
    const index = this.ava_branches.length - 1;
    console.log('ava branch index : ', index);
    this._addAvaOn(index, ava_on);
    console.log('ava between : ', ava_between);
    for (const a of ava_between) {
      this.addAvaBetween(index, null, a);
    }
  }

  public addAvaBetween(
    branchIndex: number,
    childForm: any = null,
    formValue: any = null
  ) {
    console.log('child form is : ', childForm);
    if (childForm) {
      const start_date = childForm.get('start_date')?.value;
      const end_date = childForm.get('end_date')?.value;
      const start_time = childForm.get('start_time')?.value;
      const end_time = childForm.get('start_time')?.value;
      if (!start_date && !end_date && !start_time && !end_time) {
        this.commonService.showSnackbar('Error; Please fill atleast on field!');
        return;
      }
    }
    console.log('form value is : ', formValue);
    let avaBetween = this.fb.group({
      id: [''],
      start_date: [''],
      end_date: [''],
      start_time: [''],
      end_time: [''],
    });
    if (formValue) {
      const { id = '', dates = {} } = formValue;
      console.log('form value id : ', id);
      console.log('form value date is : ', dates);
      const { date1 = '', date2 = '', time1 = '', time2 = '' } = dates;
      console.log('form value values : ', { date1, date2, time1, time2 });
      avaBetween = this.fb.group({
        id: [id],
        start_date: [date1],
        end_date: [date2],
        start_time: [time1],
        end_time: [time2],
      });
    }
    this.getAvaBetweenArray(branchIndex).push(avaBetween);
  }

  public getAvaOnFormArray(branchIndex: number): FormArray {
    return this.ava_branches.at(branchIndex).get('ava_on') as FormArray;
  }

  private _addAvaOn(index: number, avaOnDb: any[] = []) {
    const llength = this.daysOfWeek.length;
    for (let i = 0; i < llength; i++) {
      const val = avaOnDb.length > 0 ? !!avaOnDb[i] : true;
      const avaOn = this.fb.group({
        day: [val],
      });
      this.getAvaOnFormArray(index).push(avaOn);
    }
  }

  public onRemoveAvaBetweenClick(
    branchIndex: number,
    avaBetweenIndex: number,
    childForm: any
  ) {
    const start_date = childForm.get('start_date')?.value;
    const end_date = childForm.get('end_date')?.value;
    const start_time = childForm.get('start_time')?.value;
    const end_time = childForm.get('start_time')?.value;
    if (start_date || end_date || start_time || end_time) {
      const con = confirm('You want to remove the form?');
      if (con) {
        this.getAvaBetweenArray(branchIndex).removeAt(avaBetweenIndex);
      }
    } else {
      this.getAvaBetweenArray(branchIndex).removeAt(avaBetweenIndex);
    }
  }

  private _getMenuItemDetailsFromBranch() {
    if (!this.data.menuItem) {
      return of([]);
    }
    const { id } = this.data.vendor;
    const { id: menuId, shardingId } = this.data.menuItem;
    const url = `/api/cc-backbone/branch/menu-item?vendor_id=${id}&menuId=${menuId}&shardingId=${shardingId}`;
    const req$ = this.authService.getData(url).pipe(
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          'Error; Failed to get menu items from branch!'
        )
      ),
      filter((e: any) => !!e),
      map((d) => d.data)
    );
    return req$;
  }
}
