import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private branches_: any[] = [];
  private readonly httpService: HttpClient = inject(HttpClient);

  constructor() {}

  public getBranches(params: any) {
    if (this.branches_.length > 0) {
      return of(this.branches_);
    }
    const { vendor_id, include_all = true } = params;
    const url = '/api/cc-backbone/branches?vendor_id=' + vendor_id;
    const URL = environment.FunctionURL + url;
    return this.httpService.get(URL).pipe(
      map((d: any) => d.data),
      switchMap((res: any) => {
        const { items } = res;
        this.branches_ = items;
        console.log('Branches : ', this.branches_);
        if (this.branches_.length > 0 && include_all) {
          this.branches_.unshift({
            name: 'All branches',
            id: 'all',
          });
        }
        return of(this.branches_);
      })
    );
  }
}
