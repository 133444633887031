import { Component, Inject, inject, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, CommonModule } from '@angular/common';
import { CommonService } from '../../../services/common.service';
import { MatListModule } from '@angular/material/list';
import { AuthService } from '../../../services/auth.service';
import { catchError, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';

interface DialogData {
  order: any;
  vendor: any;
}

@Component({
  selector: 'dialog-order-details',
  templateUrl: 'dialog-order-details.html',
  styleUrls: ['./dialog-order-details.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    AsyncPipe,
    MatListModule,
    CommonModule,
    MatChipsModule,
    MatTabsModule,
  ],
})
export class DialogOrderDetails {
  public readonly title: string = 'Order details';
  public readonly btnText: string = 'Place order';
  public readonly cartItems: any[] = [];
  private readonly commonService = inject(CommonService);
  private readonly authService = inject(AuthService);
  public req$: Observable<any> = of([]);

  public appliedSurcharges: any[] = [];
  public appliedDiscounts: any[] = [];

  public displayTotal: string = '0';
  public displaySubTotal: string = '0';
  public readonly notes: string[] = [];
  public readonly addressString: string = '';
  public readonly branch: any = null;
  public orderHistory: any = {
    loaded: false,
    start_after: null,
    orders: [],
  };

  constructor(
    public readonly dialogRef: MatDialogRef<DialogOrderDetails>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {
    console.log('Data received for confirm dialog : ', this.data);
    const {
      cart = [],
      discounts = [],
      surcharges = [],
      total,
      subTotal,
      notes = [],
      customer = {},
      branch_id,
      branch_name = '',
    } = this.data.order;
    this.cartItems = this._buildCart(cart);
    this.appliedDiscounts = discounts;
    this.appliedSurcharges = surcharges;
    this.displayTotal = total + ' QAR';
    this.displaySubTotal = subTotal + ' QAR';
    this.notes = notes;
    const { address } = customer;
    if (address) {
      const { branch, id, created_at, ...newAddress } = address;
      this.addressString = Object.values(newAddress).join(',');
    }
    this.branch = { id: branch_id, name: branch_name };
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  private _buildCart(cart: any[]) {
    console.log('cart items : ', cart);
    const cartItems: any[] = [];
    for (const c of cart) {
      const { displayTotal, idString, addOns, ...obj } = c;
      console.log('Add ons : ', addOns);
      const newAddOns: any[] = [];
      for (const a of addOns) {
        const { checked, ...newAddOnObj } = a;
        newAddOns.push(newAddOnObj);
      }
      obj['addOns'] = newAddOns;
      cartItems.push(obj);
    }
    console.log('new cart items : ', cartItems);
    return cartItems;
  }

  private _getOrderHistory() {
    this.commonService.showLoader();
    const url = `/api/cc-backbone/order/history/${this.data.order.id}?vendor_id=${this.data.vendor.id}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          'Error; Failed to get order history'
        )
      ),
      filter((e: any) => e && true),
      map((d: any) => d.data),
      map((d) => {
        console.log('History of order data : ', d);
        const { history = [], start_after } = d;
        this.orderHistory.loaded = true;
        this.orderHistory.orders = history;
        this.orderHistory.start_after = start_after;
      })
    );
  }

  public onChangeInTab($eve: MatTabChangeEvent) {
    console.log('Tab has changed : ', $eve);
    if ($eve.index == 1) {
      if (!this.orderHistory.loaded) {
        this._getOrderHistory();
      }
    }
  }
}
