import { Component, inject, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonService } from '../../../services/common.service';
import { AuthService } from '../../../services/auth.service';
import { catchError, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';

interface DialogData {
  agent: any;
}

@Component({
  selector: 'dialog-add-agent',
  templateUrl: 'dialog-add-agent.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    AsyncPipe,
    MatSlideToggleModule,
    MatIconModule,
    MatSelectModule,
  ],
  providers: [],
})
export class DialogAddAgent {
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  public readonly form: FormGroup;
  public req$: Observable<any> = of([]);
  public readonly title: string = 'Add agent';
  public readonly subTitle: string = 'Add new agent';
  public readonly btnText: string = 'Save';
  public pwdIcon: string = 'visibility';
  public pwdType: string = 'password';
  public branches$: Observable<any[]> = of([]);
  constructor(
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAddAgent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    console.log('Data is : ', data);
    this.form = this._buildForm();
    if (this.data.agent) {
      this.title = 'Edit Agent';
      this.subTitle = `Edit agent details @ ${this.data.agent.email}`;
      this.btnText = 'Update';
      console.log('agent details : ', this.data.agent);
      const { name = '', is_disabled, email } = this.data.agent;
      const obj = {
        name,
        is_disabled,
        email,
      };
      this.form.patchValue(obj);
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onPwdIconClick() {
    if (this.pwdType == 'password') {
      this.pwdType = 'text';
      this.pwdIcon = 'visibility_off';
    } else {
      this.pwdType = 'password';
      this.pwdIcon = 'visibility';
    }
  }

  public onSaveClick(): void {
    console.log('save clicked');
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.commonService.showSnackbar('Error; Invalid form!');
      return;
    }
    const url = '/api/cc-backbone/agents';
    const keys = ['name', 'email', 'pwd', 'cPwd', 'cancelPwd'];
    const obj: any = {};
    let invalid: boolean = false;
    for (const k of keys) {
      let value = this.f[k].value;
      console.log('K is : ', k);
      console.log('value is : ', value);
      if (typeof value == 'string') {
        value = value.trim();
      } else if (typeof value == 'boolean') {
        value = value;
      } else {
        value = '';
      }

      if (k == 'name' && !value) {
        this.f[k].setErrors({ required: true });
        invalid = true;
      }

      obj[k] = value;
      if (this.data.agent) {
        if (k == 'pwd') {
          const cPwd = this.f['cPwd'].value;
          if (value != cPwd) {
            this.f['cPwd'].setErrors({ mismatch: true });
            invalid = true;
          }
        }
      } else {
        if (k == 'pwd') {
          const cPwd = this.f['cPwd'].value;
          if (!value || value != cPwd) {
            this.f['cPwd'].setErrors({ mismatch: true });
            invalid = true;
          }
          if (!value) {
            this.f[k].setErrors({ required: true });
            invalid = true;
          }
        }
      }
    }
    if (invalid) {
      this.commonService.showSnackbar('Error; Invalid form');
      return;
    }
    obj['id'] = this.data.agent ? this.data.agent.id : null;
    console.log('Object : ', obj);
    this.commonService.showLoader();
    this.req$ = this.authService.postData(url, obj).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) => {
        const { extras } = e.error;
        if (extras) {
          const { code, field } = extras;
          if (code && field) {
            this.f[field].setErrors({ [code]: true });
          }
        }
        return this._handleHttpError(
          e,
          this.data
            ? 'Error; Failed to update agent'
            : 'Error; Failed to create agent'
        );
      }),
      filter((e) => !!e),
      map((d: any) => d),
      tap((d) => {
        console.log('Response from backbone : ', d);
        const { data, msg } = d;
        this.commonService.showSnackbar(msg);
        this.dialogRef.close({ ...data });
      })
    );
  }

  private _buildForm() {
    let form = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      pwd: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(30),
        ],
      ],
      cPwd: ['', [Validators.required]],
      cancelPwd: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.minLength(6),
        ],
      ],
    });
    if (this.data.agent) {
      const { name = '', email = '' } = this.data.agent;
      form = this.fb.group({
        name: [name, [Validators.required]],
        email: [
          { value: email, disabled: true },
          [Validators.required, Validators.email],
        ],
        pwd: ['', [Validators.minLength(6), Validators.maxLength(30)]],
        cPwd: ['', []],
        cancelPwd: ['', [Validators.maxLength(30), Validators.minLength(6)]],
      });
    }
    return form;
  }

  public get f() {
    return this.form.controls;
  }

  private _handleHttpError(e: any, msg: string) {
    let msg_: string = '';
    if (e instanceof HttpErrorResponse) {
      msg_ = e.error.msg || msg;
    }
    this.commonService.showSnackbar(msg_);
    return of(null);
  }
}
