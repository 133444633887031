<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
    <br>
    <div class="row" [formGroup]="form">
        <div class="col-4">
            <mat-form-field class="w-100">
                <mat-label>Display name</mat-label>
                <input formControlName="name" matInput type="text" placeholder="Display name">
                @if (f && f['name'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['name'].hasError('maxlength')) {
                <mat-error>Max name length is 100</mat-error>
                }
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field class="w-100">
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput type="email" placeholder="Email">
                @if (f && f['email'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['email'].hasError('email')) {
                <mat-error>Invalid email</mat-error>
                }
                @if (f && f['email'].hasError('already_in_use')) {
                <mat-error>Email already in use</mat-error>
                }
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field class="w-100">
                <mat-label>Password</mat-label>
                <input formControlName="pwd" matInput [type]="pwdType" placeholder="user password">
                <mat-icon (click)="onPwdIconClick()" matSuffix>{{pwdIcon}}</mat-icon>
                @if (f && f['pwd'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['pwd'].hasError('minlength')) {
                <mat-error>Minimum 6 charector required</mat-error>
                }
                @if (f && f['pwd'].hasError('maxlength')) {
                <mat-error>Maximum 30 charector allowed</mat-error>
                }
                <mat-hint>Password must be at least 6 characters</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <br>
    <div class="row" [formGroup]="form">
        <div class="col-5">
            <mat-form-field class="w-100">
                <mat-label>Confirm Password</mat-label>
                <input formControlName="cPwd" matInput type="password" placeholder="User password">
                @if (f && f['cPwd'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['cPwd'].hasError('mismatch')) {
                <mat-error>Password not matching</mat-error>
                }
            </mat-form-field>
        </div>
        <div class="col-5">
            <mat-form-field class="w-100">
                <mat-label>Order cancellation password</mat-label>
                <input formControlName="cancelPwd" matInput type="password" placeholder="order cancellation password">
                @if (f && f['cancelPwd'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['cancelPwd'].hasError('minlength')) {
                <mat-error>Minimum 6 charector required</mat-error>
                }
                @if (f && f['cancelPwd'].hasError('maxlength')) {
                <mat-error>Maximum 30 charector allowed</mat-error>
                }
                <mat-hint>Password must be at least 6 characters</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-2 d-flex align-items-center">
            <mat-slide-toggle formControlName="is_disabled">Disabled?</mat-slide-toggle>
        </div>
    </div>
    <br>
    <div class="row" [formGroup]="form">
        <div class="col-5">
            <mat-form-field class="w-100">
                <mat-label>Branch</mat-label>
                <mat-select formControlName="branch">
                    @for (food of branches$|async; track food) {
                    <mat-option [value]="food.id">{{food.name}}</mat-option>
                    }
                </mat-select>
                @if (f && f['branch'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onNoClick()">Close</button>
    <button mat-button type="button" (click)="onSaveClick()">{{btnText}}</button>
</mat-dialog-actions>

@if (req$|async) {}