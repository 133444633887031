<h2 mat-dialog-title>
    <span>{{title + ' #'+data.order.order_number}} </span>
</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <mat-tab-group (selectedTabChange)="onChangeInTab($event)">
                <mat-tab label="Details">
                    <br>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex w-100 justify-content-between">
                                        <div class="m-0 d-flex justify-content-center align-items-center"
                                            style="gap: 10px">
                                            <span class="material-icons"> account_circle </span>
                                            @if (this.data.order.customer) {
                                            <span class="text-muted"> {{data.order.customer.name}} </span>
                                            }@else {
                                            <span class="text-muted"> -- </span>
                                            }

                                        </div>
                                        <div class="m-0 d-flex justify-content-center align-items-center"
                                            style="gap: 10px">
                                            <span class="material-icons"> phone </span>
                                            @if (this.data.order.customer) {
                                            <span class="text-muted"> {{data.order.customer.phone}} </span>
                                            }@else {
                                            <span class="text-muted"> XXXX XXXX </span>
                                            }
                                        </div>
                                    </div>
                                    @if (addressString.length) {
                                    <hr />
                                    <p class="m-0">{{ addressString }}</p>
                                    <hr />
                                    }@else{
                                    <hr />
                                    }

                                    <div class="d-flex w-100 justify-content-between">
                                        <div class="m-0 d-flex justify-content-center align-items-center"
                                            style="gap: 10px">
                                            <mat-chip-set>
                                                <mat-chip color="primary">
                                                    <span matChipAvatar class="material-icons">restaurant</span>
                                                    @if (data.vendor) {
                                                    <span> {{data.vendor.name}} </span>
                                                    }@else {
                                                    <span>---</span>
                                                    }
                                                </mat-chip>
                                            </mat-chip-set>
                                        </div>
                                        <div class="m-0 d-flex justify-content-center align-items-center"
                                            style="gap: 10px">
                                            <span class="material-icons"> place </span>
                                            @if (branch) {
                                            <span class="text-muted"> {{branch.name}} </span>
                                            }@else {
                                            <span class="text-muted">---</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-8">
                            <div class="card confirm-card">
                                <div class="card-header">
                                    <table class="table item-table m-0">
                                        <thead>
                                            <tr style="background: inherit;border: 0 solid transparent;">
                                                <th style="width: 60%;">Item</th>
                                                <th style="width: 10%;">Qty</th>
                                                <th style="width: 10%;">Sub total</th>
                                                <th style="width: 10%;">Total</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div class="card-body" style="max-height: 50vh;overflow: auto;">
                                    <table class="table item-table">
                                        <tbody>
                                            @for(item of cartItems;track item;let index=$index){
                                            <tr>
                                                <td style="width: 60%;">
                                                    <ul class="parent">
                                                        <li class="container-1">
                                                            <p class="d-flex justify-content-start align-items-center"
                                                                style="gap: 10px">
                                                                <span style="cursor: pointer">{{
                                                                    item.name + " - " }}
                                                                    <small style="font-size: 11px; font-weight: bold">{{
                                                                        "(" +
                                                                        item.unit_price +
                                                                        "
                                                                        QAR)"
                                                                        }}</small>
                                                                </span>

                                                            </p>
                                                            <ul>
                                                                @for(subItem of item.addOns;track subItem){
                                                                <li>
                                                                    <p class="d-flex justify-content-start align-items-center"
                                                                        style="gap: 10px; padding-left: 5px">
                                                                        <span>{{
                                                                            subItem.name +
                                                                            " (" +
                                                                            subItem.unit_price +
                                                                            " QAR)" +
                                                                            " -
                                                                            " +
                                                                            subItem.qty
                                                                            }}</span>
                                                                    </p>
                                                                </li>
                                                                } @for(note of item.notes;track note){
                                                                <li class="empty-1">
                                                                    <p class="d-flex justify-content-start align-items-center"
                                                                        style="
                                              gap: 10px;
                                              padding-left: 5px;
                                              cursor: pointer;
                                            ">
                                                                        <span> {{note}} </span>
                                                                    </p>
                                                                </li>
                                                                }
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td style="width: 10%;">
                                                    <div
                                                        class="d-flex justify-content-start align-items-center qty-input">
                                                        <span> {{item.qty}} </span>
                                                    </div>
                                                </td>
                                                <td style="width: 10%;">
                                                    <p>{{ item.itemTotal }}</p>
                                                    @if (
                                                    item.addOns.length>0
                                                    ) {
                                                    <p>{{ item.addOnTotal }}</p>
                                                    }

                                                </td>
                                                <td style="width: 10%;">{{ item.total }}</td>
                                            </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer">

                                    <div class="row">
                                        <div class="col-7"></div>
                                        <div class="col-5">
                                            <table class="table table-borderless total-table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Subtotal</td>
                                                        <td>{{ displaySubTotal }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            @if (appliedDiscounts.length) {
                                            <hr class="m-0" style="border-style: dotted;">
                                            }
                                            <table class="table table-borderless total-table mb-0">
                                                <tbody>
                                                    @for (item of appliedDiscounts; track item) {
                                                    <tr>
                                                        <td class="text-muted">{{ item.name }}</td>
                                                        <td class="text-muted">{{ item.displayAmount }}</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            @if (appliedDiscounts.length) {
                                            <hr class="m-0" style="border-style: dotted;">
                                            }
                                            <table class="table table-borderless total-table mb-0">
                                                <tbody>
                                                    @for (item of appliedSurcharges; track item) {
                                                    <tr>
                                                        <td>{{ item.name }}</td>
                                                        <td>{{ item.displayAmount }}</td>
                                                    </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            <hr class="m-0">
                                            <table class="table table-borderless total-table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th>{{ displayTotal }}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            @if (notes.length > 0) {
                            <div class="card note-card">
                                <div class="card-header">
                                    <h4 class="m-0">Order notes</h4>
                                </div>
                                <div class="card-body p-0">
                                    <table class="table">
                                        <tbody>
                                            @for (note of notes; track note; let last=$last) {
                                            <tr>
                                                <td style="font-size: 12px;"
                                                    [ngStyle]="{ 'border-bottom-width': last ?'0': null }">
                                                    {{note}}
                                                </td>
                                            </tr>
                                            }

                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            }

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="History">
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                        <th>
                                            Created by
                                        </th>
                                        <th>
                                            Created at
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (item of orderHistory.orders; track $index) {
                                    <tr>
                                        <td>
                                            {{$index+1}}
                                        </td>
                                        <td>
                                            {{item.action}}
                                        </td>
                                        <td>
                                            @if (item.created_by) {
                                            <span>{{ item.created_by.name||item.created_by.email||'--' }}</span>
                                            }@else {
                                            <span>--</span>
                                            }
                                        </td>
                                        <td>
                                            @if (item.created_at) {
                                            {{ item.created_at|date:'medium'}}
                                            }@else {
                                            <span>--</span>
                                            }

                                        </td>
                                    </tr>

                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onNoClick()">Close</button>
</mat-dialog-actions>
@if (req$|async) {}