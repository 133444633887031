import { Component, inject, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonService } from '../../../services/common.service';
import { AuthService } from '../../../services/auth.service';
import { catchError, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NumberPatterns } from '../../../constants/patterns';
import { HttpErrorResponse } from '@angular/common/http';

interface DialogData {}

@Component({
  selector: 'dialog-add-vendor',
  templateUrl: 'dialog-add-vendor.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    AsyncPipe,
    MatSlideToggleModule,
  ],
  providers: [],
})
export class DialogAddVendor {
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  public readonly form: FormGroup;
  public req$: Observable<any> = of([]);
  constructor(
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAddVendor>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = this._buildForm();
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onSaveClick(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const url = '/api/cc-backbone/vendors';
    const keys = [
      'name',
      'is_disabled',
      'whatsapp',
      'normal',
      'mobile',
      'threecx',
    ];
    const obj: any = {};
    let invalid: boolean = false;
    for (const k of keys) {
      let value = this.f[k].value;
      if (typeof value == 'string') {
        value = value.trim();
      } else {
        value = '';
      }
      if (k == 'name' && !value) {
        this.f[k].setErrors({ required: true });
        invalid = true;
      }
      if (k == 'threecx') {
        if (!value) {
          this.f[k].setErrors({ required: true });
          invalid = true;
        } else {
          const no_value = Number(value);
          if (isNaN(no_value)) {
            this.f[k].setErrors({ pattern: true });
            invalid = true;
          } else if (!value.match(NumberPatterns.THREECX_PHONE)) {
            this.f[k].setErrors({ pattern: true });
            invalid = true;
          }
        }
      }
      if (k == 'normal') {
        if (!value) {
          this.f[k].setErrors({ required: true });
          invalid = true;
        } else {
          const no_value = Number(value);
          console.log('value of landline : ', no_value);
          if (isNaN(no_value)) {
            this.f[k].setErrors({ pattern: true });
            invalid = true;
          } else if (!value.match(NumberPatterns.LANDLINE_PHONE)) {
            this.f[k].setErrors({ pattern: true });
            invalid = true;
          }
        }
      }
      obj[k] = value;
    }
    if (invalid) {
      return;
    }
    this.commonService.showLoader();
    this.req$ = this.authService.postData(url, obj).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) => this._handleHttpError(e, 'Failed to create vendor')),
      filter((e) => (e ? true : false)),
      map((d: any) => d.data),
      map((d: any) => d.vendor),
      tap((d) => {
        this.dialogRef.close({ ...d });
      })
    );
  }

  private _buildForm() {
    let form = this.fb.group({
      name: ['', [Validators.required]],
      normal: ['', [Validators.required]],
      whatsapp: [''],
      threecx: ['', [Validators.required]],
      mobile: [''],
      is_disabled: [false],
    });
    return form;
  }

  public get f() {
    return this.form.controls;
  }

  private _handleHttpError(e: any, msg: string) {
    let msg_: string = '';
    if (e instanceof HttpErrorResponse) {
      msg_ = e.error.msg || msg;
    }
    return of(null);
  }
}
