<h2 mat-dialog-title> {{data.discount ? 'Update discount' : 'Add discount'}} </h2>
<mat-dialog-content class="pb-0">
    <br>
    <ng-container [formGroup]="form">
        <div class="row">
            <div class="col-4">
                <mat-form-field class="w-100">
                    <mat-label>Discount name</mat-label>
                    <input formControlName="name" matInput type="text" placeholder="Discount name">
                    @if (f && f['name'].hasError('required')) {
                    <mat-error>Field required</mat-error>
                    }
                    @if (f && f['name'].hasError('maxlength')) {
                    <mat-error>Max name length is 100</mat-error>
                    }
                    <mat-hint>Max 100 charector</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-2 d-flex align-items-center">
                <mat-slide-toggle formControlName="is_disabled">Disabled?</mat-slide-toggle>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>Discount value</mat-label>
                    <input formControlName="value" matInput type="number" placeholder="Discount value">
                    @if (f && f['value'].hasError('required')) {
                    <mat-error>Field required</mat-error>
                    }
                    @if (f && f['value'].hasError('min')) {
                    <mat-error>Min value should be zero or above</mat-error>
                    }
                    <mat-hint>Min value is 0</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="w-100">
                    <mat-label>Minimum order</mat-label>
                    <input formControlName="min_order" matInput type="number" placeholder="Minimum order value">
                    @if (f && f['min_order'].hasError('required')) {
                    <mat-error>Field required</mat-error>
                    }
                    @if (f && f['min_order'].hasError('min')) {
                    <mat-error>Min value should be zero or above</mat-error>
                    }
                    <mat-hint>If there is no value or min value is zero, Discount will be applied to all
                        order</mat-hint>
                </mat-form-field>
            </div>
        </div>
        <br>

        <div class="row">
            <div class="col-4">

                <mat-form-field class="w-100">
                    <mat-label>Discount type</mat-label>
                    <mat-select formControlName="type">
                        @for (food of discontTypes; track food) {
                        <mat-option [value]="food">{{food.name}}</mat-option>
                        }
                    </mat-select>
                    @if (f && f['type'].hasError('required')) {
                    <mat-error>Field required</mat-error>
                    }

                </mat-form-field>

            </div>
            <div class="col-4">
                <mat-form-field class="w-100">
                    <mat-label>Valid from</mat-label>
                    <input (dateInput)="onValidFromDateChange( $event)" [min]="minDate" formControlName="valid_from"
                        placeholder="DD-MM-YYYY" matInput [matDatepicker]="picker">
                    <mat-hint>DD-MM-YYYY, Just ignor if available irrespective of date</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    @if (f && f['valid_from'].hasError('matDatepickerMin')) {
                    <mat-error> Minimum date should be Today </mat-error>
                    }@else if(f && f['valid_from'].errors) {
                    <mat-error>Invalid date</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="w-100">
                    <mat-label>Valid To</mat-label>
                    <input formControlName="valid_to" [min]="validToMinDate" placeholder="DD-MM-YYYY" matInput
                        [matDatepicker]="picker1">
                    <mat-hint>DD-MM-YYYY, Just ignor if available irrespective of date</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    @if (f && f['valid_to'].hasError('matDatepickerMin')) {
                    <mat-error> Minimum date should be Greater than Valid from! </mat-error>
                    }
                    @else if(f && f['valid_to'].errors) {
                    <mat-error>Invalid date</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>
        <br>

    </ng-container>

</mat-dialog-content>
<mat-dialog-actions [ngClass]=" data.discount? 'd-flex justify-content-between pt-0':'d-flex justify-content-end pt-0'">
    @if (this.data.discount) {
    <div>
        <button mat-button type="button" color="warn" (click)="onDeleteClick()">Delete</button>
    </div>}
    <div>
        <button mat-button type="button" (click)="onNoClick()">Close</button>
        <button mat-button type="button" (click)="onSaveClick()">Save</button>
    </div>
</mat-dialog-actions>

@if (req$|async) {}