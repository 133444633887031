export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCNyVN0ViYSzze_y61DU76g8t6Rn3d5SEQ',
    authDomain: 'cx-call-center-pos.firebaseapp.com',
    projectId: 'cx-call-center-pos',
    storageBucket: 'cx-call-center-pos.appspot.com',
    messagingSenderId: '196936459806',
    appId: '1:196936459806:web:0334f964da97600762300f',
    measurementId: 'G-HXK9XYM05F',
  },
  FunctionURL: 'https://qa-cc-backbone.craftingcodes.org',
  connectToEmulator: false,
};
