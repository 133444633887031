import { Injectable, inject } from '@angular/core';
import { Auth, authState, user } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { docData, Firestore, doc } from '@angular/fire/firestore';
import { FirebaseCollection } from '../constants/firebase-constants';
import { of, shareReplay, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly auth = inject(Auth);
  private readonly authState$ = authState(this.auth);
  private readonly currentUser$ = user(this.auth);
  private readonly firestore = inject(Firestore);

  public readonly authStatus$ = of({
    value: false,
    reason: 'user not found',
    user: {} as any,
  });

  constructor(private readonly http: HttpClient) {
    const authStatus$ = this._getAuthStatus();
    this.authStatus$ = authStatus$;
    this.authStatus$ = this.authStatus$.pipe(shareReplay());
  }

  public get isAuthenticated$() {
    return this.authState$;
  }

  public get idToken() {
    return this.currentUser$;
  }

  public getData(url: string) {
    const URL = environment.FunctionURL + url;
    return this.http.get(URL);
  }

  public postData(url: string, data: any) {
    const URL = environment.FunctionURL + url;
    return this.http.post(URL, data);
  }

  public deleteData(url: string) {
    const URL = environment.FunctionURL + url;
    return this.http.delete(URL);
  }

  public putData(url: string, data: any) {
    const URL = environment.FunctionURL + url;
    return this.http.put(URL, data);
  }

  private _getAuthStatus() {
    const getAuthStatus = this.authState$.pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          const userDocRef = doc(
            this.firestore,
            `${FirebaseCollection.USERS}/${user.uid}`
          );
          const doc$ = docData(userDocRef);
          const m = doc$.pipe(
            switchMap((userDoc) => {
              if (userDoc) {
                console.log('user doc data : ', userDoc);
                if (userDoc['is_deleted']) {
                  return of({
                    value: false,
                    reason: 'user has been deleted',
                    user: userDoc,
                  });
                } else if (userDoc['is_disabled']) {
                  return of({
                    value: false,
                    reason: 'user has been disabled',
                    user: userDoc,
                  });
                } else if (userDoc['user_kind'] == 'cc-agent') {
                  return of({ value: true, reason: '', user: userDoc });
                } else if (userDoc['is_admin']) {
                  return of({ value: true, reason: '', user: userDoc });
                }
              }
              return of({ value: false, reason: 'user not found!', user: {} });
            })
          );
          return m;
        }
        return of({ value: false, reason: 'not authenticated', user: {} });
      })
    );
    return getAuthStatus;
  }
}
