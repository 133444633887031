import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { finalize, catchError, filter, map, tap, Observable, of } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddAgent } from './dialog-add-agent/dialog-add-agent';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-agents',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    DialogAddAgent,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    NgClass,
  ],
  templateUrl: './agents.component.html',
  styleUrl: './agents.component.scss',
})
export class AgentsComponent implements OnInit {
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  private readonly titleService: Title = inject(Title);
  public req$: Observable<any> = of([]);
  public title: string = 'Agents';
  public subTitle: string = 'Listing all Agents';

  public agentObj: any = {
    items: [],
    displayItems: [],
    count: 0,
    pageSize: 10,
  };

  constructor(private readonly dialog: MatDialog) {
    this.titleService.setTitle('Agents');
  }

  ngOnInit(): void {
    this._getAgents();
  }

  public _getAgents(param: any = {}) {
    let url = '/api/cc-backbone/agents';
    const { id } = param;
    if (id) {
      url += `?agent_id=${id}`;
    }
    this.commonService.showLoader();
    this.req$ = this.authService
      .getData(url)
      .pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$
      .pipe(
        catchError((e) =>
          this.commonService.handleHttpError(e, 'Error; Failed to get agents!')
        )
      )
      .pipe(filter((e1) => !!e1));
    this.req$ = this.req$.pipe(
      map((d: any) => d.data),
      tap((d: any) => {
        if (id) {
          const { items } = d;
          const item = items[0];
          if (item) {
            const { items } = this.agentObj;
            const itemsIndex = items.findIndex((e: any) => e.id == item.id);
            if (itemsIndex > -1) {
              items[itemsIndex] = item;
            } else {
              items.unshift(item);
            }

            const { displayItems } = this.agentObj;
            const displayItemsIndex = displayItems.findIndex(
              (e: any) => e.id == item.id
            );
            if (displayItemsIndex > -1) {
              displayItems[displayItemsIndex] = item;
            } else {
              displayItems.unshift(item);
            }

            this.agentObj = { ...this.agentObj, items, displayItems };
          }
        } else {
          const { items, count, limit } = d;
          if (count && !(count == -1)) {
            this.subTitle = `Listing ${count} Agents`;
          }
          console.log('agents loaded : ', d);
          this.agentObj.items = items;
          this.agentObj.displayItems = items;
          this.agentObj.count = count;
          this.agentObj.pageSize = limit || 10;
        }
      })
    );
  }

  public onAddAgentClick(agent: any = null) {
    const dialogRef = this.dialog.open(DialogAddAgent, {
      width: '50vw',
      data: { agent, source: 'agent' },
      autoFocus: false,
      hasBackdrop: true,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: (d) => {
        console.log('Data after dialog close : ', d);
        if (d) {
          d.id ? this._getAgents({ id: d.id }) : null;
        }
      },
    });
  }

  public onChangeInPaginator($event: PageEvent) {
    console.log('Page event clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { items = [] } = this.agentObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayBranches = items.slice(startIndex, endIndex);
    console.log('displayBranches : ', displayBranches);
    if (displayBranches.length) {
      this.agentObj.displayItems = displayBranches;
      return;
    }
    const length = items.length;
    const lastElement = items[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const url = `/api/cc-backbone/agents?start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.agentObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items } = d;
        this.agentObj.items = this.agentObj.items.concat(items);
        this.agentObj.items = this.agentObj.items.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.agentObj.displayItems = items;
      })
    );
  }

  public onStatusUpdateClick(item: any) {
    let text = 'You want to disable this agent?';
    if (item.is_disabled) {
      text = 'You want to enable this agent?';
    }
    const conf = confirm(text);
    if (conf) {
      this.commonService.showLoader();
      this.req$ = this.authService
        .putData(`/api/cc-backbone/agents/status/${item.id}`, {
          is_disabled: !item.is_disabled,
        })
        .pipe(
          finalize(() => {
            this.commonService.hideLoader();
          }),
          catchError((e) =>
            this.commonService.handleHttpError(
              e,
              'Error; Failed to update status!'
            )
          ),
          filter((d) => !!d),
          tap(() => {
            this.commonService.showSnackbar('Agent status has been updated!');
            this._getAgents({ id: item.id });
          })
        );
    }
  }
}
