<div class="login-window d-flex justify-content-center align-items-center">
    <div class="card border-0 mat-elevation-z2">
        <div class="card-header bg-white">
            <h2 class="m-0 mb-1">Hey, User</h2>
            <p class="m-0">Enter your login details</p>
        </div>
        <div class="card-body d-flex pb-0">
            <div class="row" [formGroup]="form">
                <div class="col-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput placeholder="Ex. mail@mail.com" formControlName="email">
                        @if(f && f['email'].hasError('required')){
                        <mat-error>Field required</mat-error>
                        }
                        @if(f && f['email'].hasError('email')){
                        <mat-error>Invalid email</mat-error>
                        }
                    </mat-form-field>
                </div>
                <hr class="border-0">
                <div class="col-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="pwdType" placeholder="Your password" formControlName="pwd">
                        <mat-icon (click)="onPwdIconClick()" matSuffix>{{pwdIcon}}</mat-icon>
                        @if(f && f['pwd'].hasError('required')){
                        <mat-error>Field required</mat-error>
                        }
                        @if(f && f['pwd'].hasError('invalid')){
                        <mat-error>Invalid email or password</mat-error>
                        }
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-footer bg-white d-flex justify-content-between">
            <button mat-button (click)="openDialog()" color="warn" type="button">Forgot password</button>
            <button mat-button (click)="onSignInClick()" type="button">Sign in</button>
        </div>
    </div>
</div>