import { Component, inject, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonService } from '../../../services/common.service';
import { Observable, of } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';

interface DialogData {
  type: string;
  title: string;
  okFn: (params: any) => {};
  item: any;
  categories: [];
}

@Component({
  selector: 'dialog-add-menu-components',
  templateUrl: 'dialog-add-menu-components.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    AsyncPipe,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSelectModule,
  ],
  providers: [],
})
export class DialogAddMenuComponent {
  private readonly commonService: CommonService = inject(CommonService);
  public readonly form: FormGroup;
  public req$: Observable<any> = of([]);
  public categories: any[] = [];
  constructor(
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAddMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.categories = this.data.categories || [];
    this.form = this._buildForm();
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onSaveClick(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.commonService.showSnackbar('Error; Invalid form!');
      return;
    }
    let name = this.f['name'].value;
    if (typeof name == 'string') {
      name = name.trim();
    } else {
      name = '';
    }
    if (!name) {
      this.f['name'].setErrors({ required: true });
      return;
    }
    let is_disabled = this.f['is_disabled'].value;
    is_disabled = !!is_disabled;
    const id = this.data.item ? this.data.item.id || null : null;
    let params: any = { name, is_disabled, id };
    if (this.data.type == 'sub_cat') {
      let name = this.f['cat'].value;
      if (typeof name == 'string') {
        const trimmed = name.trim();
        if (!trimmed) {
          this.f['cat'].setErrors({ required: true });
          return;
        }
      } else {
        name = null;
      }
      if (!name) {
        this.f['cat'].setErrors({ required: true });
        return;
      }
      params = { ...params, cat_id: name };
    }
    const dialogCloseFn = () => this.dialogRef.close();
    params = { ...params, dialogCloseFn };
    this.data.okFn(params);
  }

  private _buildForm() {
    let form: any = this.fb.group({
      name: ['', [Validators.required]],
      is_disabled: [false],
    });
    if (this.data.type == 'sub_cat') {
      form = this.fb.group({
        name: ['', [Validators.required]],
        is_disabled: [false],
        cat: ['', [Validators.required]],
      });
    }
    if (this.data.item) {
      const { name = '', is_disabled = false, cat = '' } = this.data.item;
      if (this.data.type == 'sub_cat') {
        form = this.fb.group({
          name: [name, [Validators.required]],
          is_disabled: [false],
          cat: [cat, [Validators.required]],
        });
      } else {
        form = this.fb.group({
          name: [name, [Validators.required]],
          is_disabled: [is_disabled],
        });
      }
    }
    return form;
  }

  public get f() {
    return this.form.controls;
  }

  
}
