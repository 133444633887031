import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../services/auth.service';
import { catchError, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DialogAddVendor } from './dialog-add-vendor/dialog-add-vendor';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-vendors',
  standalone: true,
  imports: [
    DatePipe,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    AsyncPipe,
    CommonModule,
    MatDialogModule,
  ],
  templateUrl: './vendors.component.html',
  styleUrl: './vendors.component.scss',
  providers: [],
})
export class VendorsComponent implements OnInit {
  public vendors: any[] = [];
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  private readonly router: Router = inject(Router);
  private readonly titleService: Title = inject(Title);
  public req$: Observable<any> = of([]);
  constructor(private readonly matDialog: MatDialog) {
    this.titleService.setTitle('Vendors');
  }
  ngOnInit(): void {
    this._getVendors();
  }

  private _getVendors() {
    this.commonService.showLoader();
    const url = '/api/cc-backbone/vendors';
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(e, 'Error; Failed to get vendors!')
      ),
      filter((e) => (e ? true : false)),
      map((d: any) => d.data),
      map((d: any) => d.vendors),
      tap((x: any[]) => (this.vendors = x))
    );
  }

  public onAddVendorClick() {
    const ref$ = this.matDialog.open(DialogAddVendor, {
      data: {},
      width: '60vw',
    });
    ref$.afterClosed().subscribe({
      next: (x) => {
        console.log('value of closed : ', x);
        if (x) {
          const { id, name } = x;
          this.commonService.showSnackbar('Vendor has been craeted');
          const ii = this.vendors.findIndex((e1) => e1.id == id);
          if (ii == -1) {
            this.vendors.push({ id, name, disabled: false });
          } else {
            this.vendors[ii] = { id, name, disabled: false };
          }
        }
      },
    });
  }

  public onVendorDetailsClick(vendor: any) {
    const { id } = vendor;
    this.router.navigate(['/vendor-details/' + id]);
  }
}
