<h2 mat-dialog-title>Add Branch</h2>
<mat-dialog-content>
    <br>
    <div class="row" [formGroup]="form">
        <div class="col-5">
            <mat-form-field class="w-100">
                <mat-label>Branch name</mat-label>
                <input formControlName="name" matInput type="text" placeholder="branch name">
                @if (f && f['name'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['name'].hasError('maxlength')) {
                <mat-error>Max name length is 100</mat-error>
                }
            </mat-form-field>
        </div>
        <div class="col-2 d-flex align-items-center">
            <mat-slide-toggle formControlName="is_disabled">Disabled?</mat-slide-toggle>
        </div>
        <div class="col-2 d-flex align-items-center">
            <mat-slide-toggle formControlName="is_closed">Closed?</mat-slide-toggle>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card mat-elevation-z1 border-0">
                <div class="card-header">
                    <p class="m-0">Phone numbers</p>
                </div>
                <div class="card-body" [formGroup]="form">
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="w-100">
                                <mat-label>Landline</mat-label>
                                <input formControlName="landline" matInput type="text" placeholder="Landline number">
                                @if (f && f['landline'].hasError('required')) {
                                <mat-error>Field required</mat-error>
                                }
                                @if (f && f['landline'].hasError('pattern')) {
                                <mat-error>Invalid number!</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="w-100">
                                <mat-label>Mobile</mat-label>
                                <input formControlName="mobile" matInput type="text" placeholder="Mobile number">
                                @if (f && f['mobile'].hasError('pattern')) {
                                <mat-error>Invalid number!</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="w-100">
                                <mat-label>Whatsapp</mat-label>
                                <input formControlName="whatsapp" matInput type="text" placeholder="Whatsapp number">
                                @if (f && f['whatsapp'].hasError('pattern')) {
                                <mat-error>Invalid number!</mat-error>
                                }
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
            <div class="card mat-elevation-z1 border-0">
                <div class="card-header">
                    <p class="m-0">Address</p>
                </div>
                <div class="card-body" [formGroup]="form">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label>Address 1</mat-label>
                                <input formControlName="address1" matInput type="text" placeholder="Address 1">
                                @if (f && f['address1'].hasError('required')) {
                                <mat-error>Field required</mat-error>
                                }
                                @if (f && f['address1'].hasError('maxlength')) {
                                <mat-error>Maximum 100 charectors</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label>Address 2</mat-label>
                                <input formControlName="address2" matInput type="text" placeholder="Address 2">
                                @if (f && f['address2'].hasError('maxlength')) {
                                <mat-error>Maximum 100 charectors</mat-error>
                                }
                            </mat-form-field>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label>Area</mat-label>
                                <input formControlName="area" matInput type="text" placeholder="Area name">
                                @if (f && f['area'].hasError('required')) {
                                <mat-error>Field required</mat-error>
                                }
                                @if (f && f['area'].hasError('maxlength')) {
                                <mat-error>Maximum 100 charectors</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label>Landmark</mat-label>
                                <input formControlName="landmark" matInput type="text" placeholder="Landmark name">
                                @if (f && f['landmark'].hasError('maxlength')) {
                                <mat-error>Maximum 100 charectors</mat-error>
                                }
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onNoClick()">Close</button>
    <button mat-button type="button" (click)="onSaveClick()">Save</button>
</mat-dialog-actions>

@if (req$|async) {}