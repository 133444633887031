<h2 mat-dialog-title>
    {{title}}
</h2>
<mat-dialog-content>
    <br>
    <div [formGroup]="form">

        <mat-accordion multi="true">
            <mat-expansion-panel [expanded]="submitted">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Basic details
                    </mat-panel-title>
                    <mat-panel-description>
                        Please fill menu basic details
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-3">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" placeholder="Menu item name">
                            <mat-icon matSuffix>fastfood</mat-icon>
                            <mat-hint>Max 100 charector</mat-hint>
                            @if (this.f && this.f['name'].hasError('required')) {
                            <mat-error>Field required</mat-error>
                            }
                            @if (this.f && this.f['name'].hasError('maxlength')) {
                            <mat-error>Max 100 charectors</mat-error>
                            }
                            @if (this.f && this.f['name'].hasError('invalidName')) {
                            <mat-error>Special charector not allowed</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Unit price</mat-label>
                            <input matInput formControlName="price" type="text" placeholder="Unit price">
                            <mat-icon matSuffix>attach_money</mat-icon>
                            @if (this.f && this.f['price'].hasError('required')) {
                            <mat-error>Field required</mat-error>
                            }
                            @if (this.f && this.f['price'].hasError('invalidValue')) {
                            <mat-error>Min value must be 0</mat-error>
                            }
                            @if (f && this.f['price'].hasError('pattern')) {
                            <mat-error>Only numbers are allowed</mat-error>
                            }
                            <mat-hint>Default value</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="w-100">
                            <mat-label>Category</mat-label>
                            <mat-select (selectionChange)="onSelectCat($event)" formControlName="cat">
                                @for (food of categories; track food) {
                                <mat-option [value]="food">{{food.name}}</mat-option>
                                }
                            </mat-select>
                            @if (f && this.f['cat'].hasError('required')) {
                            <mat-error>Field required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="w-100">
                            <mat-label>Sub Category</mat-label>
                            <mat-select formControlName="sub_cat">
                                @for (food of displaySubCategories; track food) {
                                <mat-option [value]="food">{{food.name}}</mat-option>
                                }
                            </mat-select>
                            @if (f && this.f['sub_cat'].hasError('required')) {
                            <mat-error>Field required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="submitted">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Modifiers
                    </mat-panel-title>
                    <mat-panel-description>
                        Please fill the modifiers of the item
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header d-flex align-items-center justify-content-between">
                                <p class="m-0">Mandatory Modifiers with price</p>
                                @if (pmm.length) {
                                <button (click)="onClearClickOfModifier('pmm')" class="btn btn-outline-secondary btn-sm"
                                    type="button">Clear</button>
                                }@else {
                                <button (click)="addModifierGroup('pmm')" class="btn btn-outline-success btn-sm"
                                    type="button">Add</button>
                                }
                            </div>
                            <div class="card-body">
                                @if (pmm.length ==0) {
                                <div class="w-100 d-flex flex-column align-items-center justify-content-center">
                                    <div>
                                        <small>No modifiers found!</small>
                                    </div>
                                    <div>
                                        <strong>Click the button 'Add' to add modifiers</strong>
                                    </div>
                                </div>
                                }@else{
                                <ng-container formArrayName="pmm">
                                    <ng-container *ngFor="let lessonForm of pmm.controls; let i = index;let last=last">
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="row" [formGroupName]="i">
                                                    <div class="col-4 d-flex align-items-center">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Modifier group</mat-label>
                                                            <mat-select formControlName="group_name">
                                                                @for (food of menuGroups; track food) {
                                                                <mat-option [value]="food">{{food.name}}</mat-option>
                                                                }
                                                            </mat-select>
                                                            @if (lessonForm.get('group_name')?.hasError('required')) {
                                                            <mat-error>Field required</mat-error>
                                                            }
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-8 border rounded pt-3">
                                                        <div class="row">
                                                            <div class="col-10">
                                                                <div class="row">
                                                                    <ng-container formArrayName="group_items">
                                                                        <ng-container
                                                                            *ngFor="let timeRange of getPmmItemsArray(i).controls;let j=index;let last=last">
                                                                            <ng-container [formGroupName]="j">
                                                                                <div class="col-11">
                                                                                    <div class="row">
                                                                                        <div class="col-6">
                                                                                            <mat-form-field
                                                                                                class="w-100">
                                                                                                <mat-label>Name</mat-label>
                                                                                                <input matInput
                                                                                                    placeholder="Ex. Size"
                                                                                                    formControlName="name">

                                                                                                @if(timeRange.get('name')?.hasError('required')){
                                                                                                <mat-error>
                                                                                                    Field required
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('name')?.hasError('maxlength')){
                                                                                                <mat-error>
                                                                                                    Max 100
                                                                                                    charector
                                                                                                    allowed
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('name')?.hasError('invalidName')){
                                                                                                <mat-error>
                                                                                                    name must be
                                                                                                    alphanumeric
                                                                                                </mat-error>
                                                                                                }
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                        <div class="col-6">
                                                                                            <mat-form-field
                                                                                                class="w-100">
                                                                                                <mat-label>Unit
                                                                                                    price</mat-label>
                                                                                                <input matInput
                                                                                                    placeholder="Ex. 0"
                                                                                                    type="number"
                                                                                                    formControlName="unit_price">
                                                                                                @if(timeRange.get('unit_price')?.hasError('required')){
                                                                                                <mat-error>
                                                                                                    Field required
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('unit_price')?.hasError('min')){
                                                                                                <mat-error>
                                                                                                    Min value must be
                                                                                                    zero
                                                                                                </mat-error>
                                                                                                }
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-1 d-block"
                                                                                    style="position: relative;">
                                                                                    <button
                                                                                        (click)="removeModifierItem('pmm',i,j)"
                                                                                        type="button" style="position: absolute;
                                                                                                    top: 21%;
                                                                                                    padding: 0px 5px;
                                                                                                    font-size: 15px;"
                                                                                        class="btn btn-lg btn-outline-danger">
                                                                                        <i class="fa fa-close"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            @if(last){
                                                            <div
                                                                class="col-2 d-flex align-items-center justify-content-center">
                                                                <button (click)="addModifierItem(i,'pmm',lessonForm)"
                                                                    class="btn btn-sm btn-outline-primary"
                                                                    type="button">Add
                                                                    item</button>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            @if(last){
                                            <div class="col-2 d-flex justify-content-center align-items-center"
                                                style="gap: 3px;">
                                                <div>
                                                    <button (click)="addModifierGroup('pmm',lessonForm)"
                                                        class="btn btn-sm btn-outline-success" type="button">Add
                                                    </button>
                                                </div>
                                                <div>
                                                    <button (click)="removeModiferGroup(i,'pmm')"
                                                        class="btn btn-sm btn-outline-danger" type="button">Remove
                                                    </button>
                                                </div>
                                            </div>
                                            }@else{
                                            <div class="col-2 d-flex align-items-center justify-content-center">
                                                <button (click)="removeModiferGroup(i,'pmm')"
                                                    class="btn btn-sm btn-outline-danger" type="button">Remove
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        @if (!last) {
                                        <hr>
                                        }
                                    </ng-container>
                                </ng-container>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header d-flex align-items-center justify-content-between">
                                <p class="m-0">Price Modifiers</p>
                                @if (this.pm.length) {
                                <button (click)="onClearClickOfModifier('pm')" class="btn btn-outline-secondary btn-sm"
                                    type="button">Clear</button>
                                }@else {
                                <button (click)="addModifierGroup('pm')" class="btn btn-outline-success btn-sm"
                                    type="button">Add</button>
                                }
                            </div>
                            <div class="card-body">
                                @if (pm.length ==0) {
                                <div class="w-100 d-flex flex-column align-items-center justify-content-center">
                                    <div>
                                        <small>No modifiers found!</small>
                                    </div>
                                    <div>
                                        <strong>Click the button 'Add' to add modifiers</strong>
                                    </div>
                                </div>
                                }@else{
                                <ng-container formArrayName="pm">
                                    <ng-container *ngFor="let lessonForm of pm.controls; let i = index;let last=last">
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="row" [formGroupName]="i">
                                                    <div class="col-4 d-flex align-items-center">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Modifier group</mat-label>
                                                            <mat-select formControlName="group_name">
                                                                @for (food of menuGroups; track food) {
                                                                <mat-option [value]="food">{{food.name}}</mat-option>
                                                                }
                                                            </mat-select>
                                                            @if (lessonForm.get('group_name')?.hasError('required')) {
                                                            <mat-error>Field required</mat-error>
                                                            }
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-8 border rounded pt-3">
                                                        <div class="row">
                                                            <div class="col-10">
                                                                <div class="row">
                                                                    <ng-container formArrayName="group_items">
                                                                        <ng-container
                                                                            *ngFor="let timeRange of getPmItemsArray(i).controls;let j=index;let last=last">
                                                                            <ng-container [formGroupName]="j">
                                                                                <div class="col-11">
                                                                                    <div class="row">
                                                                                        <div class="col-6">
                                                                                            <mat-form-field
                                                                                                class="w-100">
                                                                                                <mat-label>Name</mat-label>
                                                                                                <input matInput
                                                                                                    placeholder="Ex. Size"
                                                                                                    formControlName="name">

                                                                                                @if(timeRange.get('name')?.hasError('required')){
                                                                                                <mat-error>
                                                                                                    Field required
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('name')?.hasError('maxlength')){
                                                                                                <mat-error>
                                                                                                    Max 100
                                                                                                    charector
                                                                                                    allowed
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('name')?.hasError('invalidName')){
                                                                                                <mat-error>
                                                                                                    name must be
                                                                                                    alphanumeric
                                                                                                </mat-error>
                                                                                                }
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                        <div class="col-6">
                                                                                            <mat-form-field
                                                                                                class="w-100">
                                                                                                <mat-label>Unit
                                                                                                    price</mat-label>
                                                                                                <input matInput
                                                                                                    placeholder="Ex. 0"
                                                                                                    type="number"
                                                                                                    formControlName="unit_price">
                                                                                                @if(timeRange.get('unit_price')?.hasError('required')){
                                                                                                <mat-error>
                                                                                                    Field required
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('unit_price')?.hasError('min')){
                                                                                                <mat-error>
                                                                                                    Min value must be
                                                                                                    zero
                                                                                                </mat-error>
                                                                                                }
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-1 d-block"
                                                                                    style="position: relative;">
                                                                                    <button
                                                                                        (click)="removeModifierItem('pm',i,j)"
                                                                                        type="button" style="position: absolute;
                                                                                                    top: 21%;
                                                                                                    padding: 0px 5px;
                                                                                                    font-size: 15px;"
                                                                                        class="btn btn-lg btn-outline-danger">
                                                                                        <i class="fa fa-close"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            @if(last){
                                                            <div
                                                                class="col-2 d-flex align-items-center justify-content-center">
                                                                <button (click)="addModifierItem(i,'pm',lessonForm)"
                                                                    class="btn btn-sm btn-outline-primary"
                                                                    type="button">Add
                                                                    item</button>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            @if(last){
                                            <div class="col-2 d-flex justify-content-center align-items-center"
                                                style="gap: 3px;">
                                                <div>
                                                    <button (click)="addModifierGroup('pm',lessonForm)"
                                                        class="btn btn-sm btn-outline-success" type="button">Add
                                                    </button>
                                                </div>
                                                <div>
                                                    <button (click)="removeModiferGroup(i,'pm')"
                                                        class="btn btn-sm btn-outline-danger" type="button">Remove
                                                    </button>
                                                </div>
                                            </div>
                                            }@else{
                                            <div class="col-2 d-flex align-items-center justify-content-center">
                                                <button (click)="removeModiferGroup(i,'pm')"
                                                    class="btn btn-sm btn-outline-danger" type="button">Remove
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        @if (!last) {
                                        <hr>
                                        }
                                    </ng-container>
                                </ng-container>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header d-flex align-items-center justify-content-between">
                                <p class="m-0">Regular Modifiers</p>
                                @if (rm.length) {
                                <button (click)="onClearClickOfModifier('rm')" class="btn btn-outline-secondary btn-sm"
                                    type="button">Clear</button>
                                }@else {
                                <button (click)="addModifierGroup('rm')" class="btn btn-outline-success btn-sm"
                                    type="button">Add</button>
                                }
                            </div>
                            <div class="card-body">
                                @if (rm.length ==0) {
                                <div class="w-100 d-flex flex-column align-items-center justify-content-center">
                                    <div>
                                        <small>No modifiers found!</small>
                                    </div>
                                    <div>
                                        <strong>Click the button 'Add' to add modifiers</strong>
                                    </div>
                                </div>
                                }@else{
                                <ng-container formArrayName="rm">
                                    <ng-container *ngFor="let lessonForm of rm.controls; let i = index;let last=last">
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="row" [formGroupName]="i">
                                                    <div class="col-4 d-flex align-items-center">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Modifier group</mat-label>
                                                            <mat-select formControlName="group_name">
                                                                @for (food of menuGroups; track food) {
                                                                <mat-option [value]="food">{{food.name}}</mat-option>
                                                                }
                                                            </mat-select>
                                                            @if (lessonForm.get('group_name')?.hasError('required')) {
                                                            <mat-error>Field required</mat-error>
                                                            }
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-8 border rounded pt-3">
                                                        <div class="row">
                                                            <div class="col-10">
                                                                <div class="row">
                                                                    <ng-container formArrayName="group_items">
                                                                        <ng-container
                                                                            *ngFor="let timeRange of getRmItemsArray(i).controls;let j=index;let last=last">
                                                                            <ng-container [formGroupName]="j">
                                                                                <div class="col-11">
                                                                                    <div class="row">
                                                                                        <div class="col-6">
                                                                                            <mat-form-field
                                                                                                class="w-100">
                                                                                                <mat-label>Name</mat-label>
                                                                                                <input matInput
                                                                                                    placeholder="Ex. Size"
                                                                                                    formControlName="name">

                                                                                                @if(timeRange.get('name')?.hasError('required')){
                                                                                                <mat-error>
                                                                                                    Field required
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('name')?.hasError('maxlength')){
                                                                                                <mat-error>
                                                                                                    Max 100
                                                                                                    charector
                                                                                                    allowed
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('name')?.hasError('invalidName')){
                                                                                                <mat-error>
                                                                                                    name must be
                                                                                                    alphanumeric
                                                                                                </mat-error>
                                                                                                }
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                        <div class="col-6">
                                                                                            <mat-form-field
                                                                                                class="w-100">
                                                                                                <mat-label>Unit
                                                                                                    price</mat-label>
                                                                                                <input matInput
                                                                                                    placeholder="Ex. 0"
                                                                                                    type="number"
                                                                                                    formControlName="unit_price">
                                                                                                @if(timeRange.get('unit_price')?.hasError('required')){
                                                                                                <mat-error>
                                                                                                    Field required
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('unit_price')?.hasError('min')){
                                                                                                <mat-error>
                                                                                                    Min value must be
                                                                                                    zero
                                                                                                </mat-error>
                                                                                                }
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-1 d-block"
                                                                                    style="position: relative;">
                                                                                    <button
                                                                                        (click)="removeModifierItem('rm',i,j)"
                                                                                        type="button" style="position: absolute;
                                                                                                    top: 21%;
                                                                                                    padding: 0px 5px;
                                                                                                    font-size: 15px;"
                                                                                        class="btn btn-lg btn-outline-danger">
                                                                                        <i class="fa fa-close"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            @if(last){
                                                            <div
                                                                class="col-2 d-flex align-items-center justify-content-center">
                                                                <button (click)="addModifierItem(i,'rm',lessonForm)"
                                                                    class="btn btn-sm btn-outline-primary"
                                                                    type="button">Add
                                                                    item</button>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            @if(last){
                                            <div class="col-2 d-flex justify-content-center align-items-center"
                                                style="gap: 3px;">
                                                <div>
                                                    <button (click)="addModifierGroup('rm',lessonForm)"
                                                        class="btn btn-sm btn-outline-success" type="button">Add
                                                    </button>
                                                </div>
                                                <div>
                                                    <button (click)="removeModiferGroup(i,'rm')"
                                                        class="btn btn-sm btn-outline-danger" type="button">Remove
                                                    </button>
                                                </div>
                                            </div>
                                            }@else{
                                            <div class="col-2 d-flex align-items-center justify-content-center">
                                                <button (click)="removeModiferGroup(i,'rm')"
                                                    class="btn btn-sm btn-outline-danger" type="button">Remove
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        @if (!last) {
                                        <hr>
                                        }
                                    </ng-container>
                                </ng-container>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header d-flex align-items-center justify-content-between">
                                <p class="m-0">Mandatory Modifiers</p>
                                @if (mm.length) {
                                <button (click)="onClearClickOfModifier('mm')" class="btn btn-outline-secondary btn-sm"
                                    type="button">Clear</button>
                                }@else {
                                <button (click)="addModifierGroup('mm')" class="btn btn-outline-success btn-sm"
                                    type="button">Add</button>
                                }
                            </div>
                            <div class="card-body">
                                @if (mm.length ==0) {
                                <div class="w-100 d-flex flex-column align-items-center justify-content-center">
                                    <div>
                                        <small>No modifiers found!</small>
                                    </div>
                                    <div>
                                        <strong>Click the button 'Add' to add modifiers</strong>
                                    </div>
                                </div>
                                }@else{
                                <ng-container formArrayName="mm">
                                    <ng-container *ngFor="let lessonForm of mm.controls; let i = index;let last=last">
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="row" [formGroupName]="i">
                                                    <div class="col-4 d-flex align-items-center">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Modifier group</mat-label>
                                                            <mat-select formControlName="group_name">
                                                                @for (food of menuGroups; track food) {
                                                                <mat-option [value]="food">{{food.name}}</mat-option>
                                                                }
                                                            </mat-select>
                                                            @if (lessonForm.get('group_name')?.hasError('required')) {
                                                            <mat-error>Field required</mat-error>
                                                            }
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-8 border rounded pt-3">
                                                        <div class="row">
                                                            <div class="col-10">
                                                                <div class="row">
                                                                    <ng-container formArrayName="group_items">
                                                                        <ng-container
                                                                            *ngFor="let timeRange of getMmItemsArray(i).controls;let j=index;let last=last">
                                                                            <ng-container [formGroupName]="j">
                                                                                <div class="col-11">
                                                                                    <div class="row">
                                                                                        <div class="col-6">
                                                                                            <mat-form-field
                                                                                                class="w-100">
                                                                                                <mat-label>Name</mat-label>
                                                                                                <input matInput
                                                                                                    placeholder="Ex. Size"
                                                                                                    formControlName="name">

                                                                                                @if(timeRange.get('name')?.hasError('required')){
                                                                                                <mat-error>
                                                                                                    Field required
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('name')?.hasError('maxlength')){
                                                                                                <mat-error>
                                                                                                    Max 100
                                                                                                    charector
                                                                                                    allowed
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('name')?.hasError('invalidName')){
                                                                                                <mat-error>
                                                                                                    name must be
                                                                                                    alphanumeric
                                                                                                </mat-error>
                                                                                                }
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                        <div class="col-6">
                                                                                            <mat-form-field
                                                                                                class="w-100">
                                                                                                <mat-label>Unit
                                                                                                    price</mat-label>
                                                                                                <input matInput
                                                                                                    placeholder="Ex. 0"
                                                                                                    type="number"
                                                                                                    formControlName="unit_price">
                                                                                                @if(timeRange.get('unit_price')?.hasError('required')){
                                                                                                <mat-error>
                                                                                                    Field required
                                                                                                </mat-error>
                                                                                                }
                                                                                                @if(timeRange.get('unit_price')?.hasError('min')){
                                                                                                <mat-error>
                                                                                                    Min value must be
                                                                                                    zero
                                                                                                </mat-error>
                                                                                                }
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-1 d-block"
                                                                                    style="position: relative;">
                                                                                    <button
                                                                                        (click)="removeModifierItem('mm',i,j)"
                                                                                        type="button" style="position: absolute;
                                                                                                    top: 21%;
                                                                                                    padding: 0px 5px;
                                                                                                    font-size: 15px;"
                                                                                        class="btn btn-lg btn-outline-danger">
                                                                                        <i class="fa fa-close"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            @if(last){
                                                            <div
                                                                class="col-2 d-flex align-items-center justify-content-center">
                                                                <button (click)="addModifierItem(i,'mm',lessonForm)"
                                                                    class="btn btn-sm btn-outline-primary"
                                                                    type="button">Add
                                                                    item</button>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            @if(last){
                                            <div class="col-2 d-flex justify-content-center align-items-center"
                                                style="gap: 3px;">
                                                <div>
                                                    <button (click)="addModifierGroup('mm',lessonForm)"
                                                        class="btn btn-sm btn-outline-success" type="button">Add
                                                    </button>
                                                </div>
                                                <div>
                                                    <button (click)="removeModiferGroup(i,'mm')"
                                                        class="btn btn-sm btn-outline-danger" type="button">Remove
                                                    </button>
                                                </div>
                                            </div>
                                            }@else{
                                            <div class="col-2 d-flex align-items-center justify-content-center">
                                                <button (click)="removeModiferGroup(i,'mm')"
                                                    class="btn btn-sm btn-outline-danger" type="button">Remove
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        @if (!last) {
                                        <hr>
                                        }
                                    </ng-container>
                                </ng-container>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>

            <ng-container formArrayName="ava_branches">
                <ng-container *ngFor="let childForm of ava_branches.controls; let branchIndex=index;let last=last">
                    <mat-expansion-panel [expanded]="submitted">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ childForm.get('branch_name')?.value }}
                            </mat-panel-title>
                            <mat-panel-description>
                                @if (branchIndex == 0) {
                                <span>If values are same for all branches, just fill this branch only!</span>
                                }@else {
                                <span>Please fill the availabilty of branch</span>
                                }
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-container [formGroupName]="branchIndex">

                            <div class="row">
                                <div class="col-3">
                                    <mat-form-field class="w-100">
                                        <mat-label>Price</mat-label>
                                        <input type="number" matInput placeholder="Ex. 10" formControlName="price">
                                        @if(childForm.get('price')?.hasError('required')){
                                        <mat-error>
                                            Field required
                                        </mat-error>
                                        }
                                        @if(childForm.get('price')?.hasError('min')){
                                        <mat-error>
                                            Minimum value must be zero!
                                        </mat-error>
                                        }
                                        @if(childForm.get('price')?.hasError('invalidValue')){
                                        <mat-error>
                                            Must be a number
                                        </mat-error>
                                        }
                                        <mat-hint>Keep it as empty if the it as same as default value</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-checkbox formControlName="is_disabled"> Disabled? </mat-checkbox>
                                </div>
                            </div>
                            <ng-container formArrayName="ava_on">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-header d-flex align-items-center">
                                                <p class="m-0">Available week days</p>
                                            </div>
                                            <div class="card-body d-flex justify-content-between">
                                                <ng-container
                                                    *ngFor="let avaOn of getAvaOnFormArray(branchIndex).controls; let avaIndex=index;">
                                                    <ng-container [formGroupName]="avaIndex">
                                                        <div>
                                                            <mat-checkbox formControlName="day"> {{
                                                                daysOfWeek[avaIndex].long }}
                                                            </mat-checkbox>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <br>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <p class="m-0">Available between</p>
                                        </div>
                                        <div class="card-body">
                                            @if(getAvaBetweenArray(branchIndex).length ==0){
                                            <div class="d-flex flex-column justify-content-center align-items-center"
                                                style="gap: 10px;">
                                                <div>
                                                    <small>No available date found. Please click 'Add date'
                                                        button to add date range</small>
                                                </div>
                                                <div>
                                                    <button (click)="addAvaBetween(branchIndex)" type="button"
                                                        class="btn btn-sm btn-outline-primary">Add
                                                        date</button>
                                                </div>
                                            </div>
                                            }@else {
                                            <div class="row" formArrayName="ava_between">
                                                <ng-container
                                                    *ngFor="let avaBetween of getAvaBetweenArray(branchIndex).controls; let avaBetweenIndex = index;let last=last"
                                                    [formGroupName]="avaBetweenIndex">
                                                    <div class="col-10">
                                                        <div class="row">
                                                            <div class="col-3 d-flex align-items-center">
                                                                <mat-form-field class="w-100">
                                                                    <mat-label>Valid from</mat-label>
                                                                    <input formControlName="start_date" matInput
                                                                        [matDatepicker]="picker"
                                                                        placeholder="Valida from the date">
                                                                    <mat-hint>DD-MM-YYYY</mat-hint>
                                                                    <mat-datepicker-toggle matIconSuffix
                                                                        [for]="picker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #picker></mat-datepicker>
                                                                    @if
                                                                    (avaBetween.get('start_date')?.hasError('matDatepickerParse')
                                                                    )
                                                                    {
                                                                    <mat-error>Invalid date</mat-error>
                                                                    }
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-3 d-flex align-items-center">
                                                                <mat-form-field class="w-100">
                                                                    <mat-label>Valid to</mat-label>
                                                                    <input formControlName="end_date" matInput
                                                                        [matDatepicker]="picker1"
                                                                        placeholder="Valid up to the date">
                                                                    <mat-hint>DD-MM-YYYY</mat-hint>
                                                                    <mat-datepicker-toggle matIconSuffix
                                                                        [for]="picker1"></mat-datepicker-toggle>
                                                                    <mat-datepicker #picker1></mat-datepicker>
                                                                    @if
                                                                    (avaBetween.get('end_date')?.hasError('matDatepickerParse')
                                                                    )
                                                                    {
                                                                    <mat-error>Invalid date</mat-error>
                                                                    }
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-3">
                                                                <mat-form-field class="w-100" appearance="outline">
                                                                    <mat-label>From time</mat-label>
                                                                    <input matInput type="time" #start_time
                                                                        formControlName="start_time"
                                                                        placeholder="Start time">
                                                                    <mat-icon style="cursor: pointer;"
                                                                        (click)="start_time.showPicker()"
                                                                        matSuffix>alarm_on</mat-icon>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-3">
                                                                <mat-form-field class="w-100" appearance="outline">
                                                                    <mat-label>End time</mat-label>
                                                                    <input #end_time matInput type="time"
                                                                        formControlName="end_time"
                                                                        placeholder="End time">
                                                                    <mat-icon style="cursor: pointer;"
                                                                        (click)="end_time.showPicker()"
                                                                        matSuffix>alarm_on</mat-icon>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    @if (last) {
                                                    <div class="col-2 d-flex flex-column" style="gap: 5px;">
                                                        <div class="w-100">
                                                            <button type="button"
                                                                (click)="onRemoveAvaBetweenClick(branchIndex,avaBetweenIndex,avaBetween)"
                                                                class="btn btn-sm btn-outline-danger w-100">Remove</button>
                                                        </div>
                                                        <div class="w-100">
                                                            <button (click)="addAvaBetween(branchIndex,avaBetween)"
                                                                type="button"
                                                                class="btn btn-sm btn-outline-success w-100">Add
                                                                another date</button>
                                                        </div>
                                                    </div>
                                                    }@else {
                                                    <div class="col-2">
                                                        <button type="button"
                                                            (click)="onRemoveAvaBetweenClick(branchIndex,avaBetweenIndex,avaBetween)"
                                                            class="btn btn-sm btn-outline-danger w-100">Remove</button>
                                                    </div>
                                                    <hr>
                                                    }
                                                </ng-container>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                </ng-container>
            </ng-container>
        </mat-accordion>

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onNoClick()">Close</button>
    <button mat-button type="button" (click)="onSaveClick()"> {{btnText}} </button>
</mat-dialog-actions>
@if (req$|async) {}