import { Component, inject, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonService } from '../../../services/common.service';
import { AuthService } from '../../../services/auth.service';
import { catchError, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NumberPatterns } from '../../../constants/patterns';
import { HttpErrorResponse } from '@angular/common/http';

interface DialogData {
  vendor: any;
  branch: any;
}

@Component({
  selector: 'dialog-add-branch',
  templateUrl: 'dialog-add-branch.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    AsyncPipe,
    MatSlideToggleModule,
  ],
  providers: [],
})
export class DialogAddBranches {
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  public readonly form: FormGroup;
  public req$: Observable<any> = of([]);
  constructor(
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAddBranches>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = this._buildForm();
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onSaveClick(): void {
    console.log('save clicked');
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      console.log('is disabled : ', this.f['is_disabled'].value);
      this.commonService.showSnackbar('Error; Invalid form!');
      return;
    }
    const url = '/api/cc-backbone/branches/abs';
    const keys = [
      'name',
      'is_disabled',
      'whatsapp',
      'landline',
      'mobile',
      'is_closed',
      'address1',
      'address2',
      'area',
      'landmark',
    ];
    const obj: any = {};
    let invalid: boolean = false;
    for (const k of keys) {
      let value = this.f[k].value;
      console.log('K is : ', k);
      console.log('value is : ', value);
      if (typeof value == 'string') {
        value = value.trim();
      } else if (typeof value == 'boolean') {
        value = value;
      } else {
        value = '';
      }

      if (k == 'name' && !value) {
        this.f[k].setErrors({ required: true });
        invalid = true;
      }

      if (k == 'landline') {
        if (value) {
          const no_value = Number(value);
          if (isNaN(no_value)) {
            this.f[k].setErrors({ pattern: true });
            invalid = true;
          } else if (!value.match(NumberPatterns.LANDLINE_PHONE)) {
            this.f[k].setErrors({ pattern: true });
            invalid = true;
          }
        } else {
          this.f[k].setErrors({ required: true });
          invalid = true;
        }
      }

      if (k == 'address1') {
        if (!value) {
          this.f[k].setErrors({ required: true });
          invalid = true;
        }
      }

      if (k == 'area') {
        if (!value) {
          this.f[k].setErrors({ required: true });
          invalid = true;
        }
      }
      obj[k] = value;
    }
    if (invalid) {
      this.commonService.showSnackbar('Error; Invalid form');
      return;
    }
    obj['vendor_id'] = this.data.vendor.id;
    obj['id'] = this.data.branch ? this.data.branch.id : null;
    this.commonService.showLoader();
    this.req$ = this.authService.postData(url, obj).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this._handleHttpError(e, 'Error; Failed to create branch')
      ),
      filter((e) => (e ? true : false)),
      map((d: any) => d.data),
      map((d: any) => d.branch),
      tap((d) => {
        console.log('Response from backbone : ', d);
        this.dialogRef.close({ ...d });
      })
    );
  }

  private _buildForm() {
    let form = this.fb.group({
      name: ['', [Validators.required]],
      landline: [
        '',
        [
          Validators.required,
          Validators.pattern(NumberPatterns.LANDLINE_PHONE),
        ],
      ],
      whatsapp: ['', [Validators.pattern(NumberPatterns.LANDLINE_PHONE)]],
      mobile: ['', [Validators.pattern(NumberPatterns.LANDLINE_PHONE)]],
      is_disabled: [false],
      is_closed: [false],
      address1: ['', [Validators.required, Validators.maxLength(100)]],
      address2: ['', []],
      area: ['', [Validators.required, Validators.maxLength(100)]],
      landmark: ['', []],
    });
    if (this.data.branch) {
      const {
        name = '',
        phone = {},
        is_disabled = false,
        is_closed = false,
        address = {},
      } = this.data.branch;
      const { landline = '', mobile = '', whatsapp = '' } = phone;
      const {
        address1 = '',
        address2 = '',
        area = '',
        landmark = '',
      } = address;
      form = this.fb.group({
        name: [name || '', [Validators.required]],
        landline: [
          landline,
          [
            Validators.required,
            Validators.pattern(NumberPatterns.LANDLINE_PHONE),
          ],
        ],
        whatsapp: [
          whatsapp,
          [Validators.pattern(NumberPatterns.LANDLINE_PHONE)],
        ],
        mobile: [mobile, [Validators.pattern(NumberPatterns.LANDLINE_PHONE)]],
        is_disabled: [is_disabled],
        is_closed: [is_closed],
        address1: [address1, [Validators.required]],
        address2: [address2, []],
        area: [area, [Validators.required]],
        landmark: [landmark, []],
      });
    }
    return form;
  }

  public get f() {
    return this.form.controls;
  }

  private _handleHttpError(e: any, msg: string) {
    let msg_: string = '';
    if (e instanceof HttpErrorResponse) {
      msg_ = e.error.msg || msg;
    }
    this.commonService.showSnackbar(msg_);
    return of(null);
  }
}
