import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonService } from './services/common.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthService } from './services/auth.service';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarModule } from 'primeng/sidebar';

import { Auth } from '@angular/fire/auth';
import { signOut } from '@angular/fire/auth';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    SidebarModule,
    ConfirmDialogModule,
    MatChipsModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [ConfirmationService],
})
export class AppComponent {
  public titleString: string = 'Welcome, You';
  public sidebarVisible: boolean = false;
  private readonly auth: Auth = inject(Auth);
  private readonly router: Router = inject(Router);
  public readonly authService: AuthService = inject(AuthService);
  public readonly commonService: CommonService = inject(CommonService);
  private readonly confirmationService: ConfirmationService =
    inject(ConfirmationService);
  public sidebarMenuItems: any[] = [];
  public user_name: string = 'Agent';
  public readonly avatarIcon: string =
    'https://www.shareicon.net/data/512x512/2016/09/15/829453_user_512x512.png';
  constructor() {
    this._buildSidebar();
  }

  public onLogoutClick(event: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure that you want to logout?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.commonService.showLoader();
        signOut(this.auth)
          .then(() => this.router.navigate(['/login']))
          .catch((error) => {
            console.log('Error on signout : ', error);
            const s = 'error';
            const title = 'Error';
            const msg = 'Failed to sign out!';
          })
          .finally(() => this.commonService.hideLoader());
      },
      reject: () => null,
    });
  }

  private _buildSidebar() {
    this.sidebarMenuItems = [
      {
        label: 'Vendors',
        icon: 'restaurant',
        func: () => {
          console.log('func of person called called');
          this.router.navigate(['/vendors']);
        },
      },
      {
        label: 'Agents',
        icon: 'support_agent',
        func: () => {
          console.log('func of agents called');
          this.router.navigate(['/agents']);
        },
      },
    ];
  }

  public onActivate($event: any) {
    console.log('Router activated : ', $event);
    this.commonService.makePageLoaded();
  }
}
