<h2 mat-dialog-title>Forgot password?</h2>
<mat-dialog-content>
    <hr class="border-0">
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Email address</mat-label>
                <input matInput type="email" [formControl]="form">
                @if(form.hasError('required')){
                <mat-error>Field required</mat-error>
                }
                @if(form.hasError('email')){
                <mat-error>Invalid email</mat-error>
                }
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Close</button>
    <button mat-button (click)="onSubmitClick()">Submit</button>
</mat-dialog-actions>