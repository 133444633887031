import { Component, inject, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonService } from '../../../services/common.service';
import { AuthService } from '../../../services/auth.service';
import { catchError, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormConstants } from '../../../constants/patterns';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import moment from 'moment';

interface DialogData {
  vendor: any;
  surcharge: any;
}

@Component({
  selector: 'dialog-add-surcharge',
  templateUrl: 'dialog-add-surcharge.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    AsyncPipe,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    CommonModule,
  ],
  providers: [],
})
export class DialogAddSurcharge {
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  public readonly form: FormGroup;
  public req$: Observable<any> = of([]);
  public readonly minDate: Date = new Date();
  public validToMinDate: Date = new Date();
  public readonly discontTypes: any[] = [
    { name: 'Percentage', val: 'per' },
    { name: 'Value', val: 'val' },
  ];
  constructor(
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogAddSurcharge>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    console.log('Dialog data : ', this.data);
    this.form = this._buildForm();
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onSaveClick(): void {
    console.log('save clicked');
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.commonService.showSnackbar('Error; Invalid form!');
      return;
    }
    const url = '/api/cc-backbone/abs/surcharge';
    const keys = [
      'name',
      'is_disabled',
      'valid_from',
      'valid_to',
      'type',
      'max_order',
      'value',
    ];
    let isInvalid = false;
    const obj: any = {};
    for (const k of keys) {
      console.log('Key is : ', k);
      let value = this.f[k].value;
      console.log('value is : ', value);
      if (k == 'name') {
        if (typeof value == 'string') {
          value = value.trim();
          if (!value) {
            this.f[k].setErrors({ required: true });
            isInvalid = true;
          }
        } else {
          isInvalid = true;
        }
      }
      if (k == 'type') {
        if (typeof value == 'object') {
          value = value.val;
          if (!value) {
            this.f[k].setErrors({ required: true });
            isInvalid = true;
          }
        } else {
          isInvalid = true;
        }
      }
      if (k == 'valid_from' || k == 'valid_to') {
        if (moment.isMoment(value)) {
          value = value.format('YYYY-MM-DD');
          console.log('value is moment and has been cpnverted: ', value);
        }
      }
      obj[k] = value;
    }
    if (isInvalid) {
      this.commonService.showSnackbar('Error; Invalid form!');
      return;
    }
    const { id: vendor_id } = this.data.vendor;
    const discount_id = this.data.surcharge
      ? this.data.surcharge.id || null
      : null;

    const payload: any = {};
    payload['vendor_id'] = vendor_id;
    payload['id'] = discount_id;
    payload['surcharge'] = { ...obj };
    console.log('Payload to server : ', obj);
    this.commonService.showLoader();
    this.req$ = this.authService
      .postData(url, payload)
      .pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$.pipe(
      catchError((e) =>
        this._handleHttpError(
          e,
          this.data.surcharge
            ? 'Error; Failed to update discount'
            : 'Error; Failed to create discount'
        )
      ),
      filter((e) => !!e),
      tap((d: any) => {
        console.log('Response from server for surcharge add or update : ', d);
        const { msg } = d;
        this.commonService.showSnackbar(msg);
        this.dialogRef.close({
          id: this.data.surcharge ? this.data.surcharge.id : null,
        });
      })
    );
  }

  private _buildForm() {
    let form = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.maxLength(FormConstants.MAX_CHAR)],
      ],
      is_disabled: [false],
      max_order: [0, [Validators.min(0), Validators.required]],
      value: [0, [Validators.min(0)]],
      valid_from: [''],
      valid_to: [''],
      type: ['', [Validators.required]],
    });
    if (this.data.surcharge) {
      const {
        name = '',
        is_disabled = false,
        max_order = 0,
        value = 0,
        type,
        valid_from_as_date = '',
        valid_to_as_date = '',
      } = this.data.surcharge;
      console.log({ valid_from_as_date, valid_to_as_date });
      const type_ = this.discontTypes.find((e1) => e1.val == type);
      console.log('Type is : ', type_);
      form = this.fb.group({
        name: [
          name,
          [Validators.required, Validators.maxLength(FormConstants.MAX_CHAR)],
        ],
        is_disabled: [is_disabled],
        max_order: [max_order, [Validators.min(0)]],
        value: [value, [Validators.min(0)]],
        valid_from: [valid_from_as_date],
        valid_to: [valid_to_as_date],
        type: [type_, [Validators.required]],
      });
    }
    return form;
  }

  public get f() {
    return this.form.controls;
  }

  private _handleHttpError(e: any, msg: string) {
    let msg_: string = '';
    if (e instanceof HttpErrorResponse) {
      msg_ = e.error.msg || msg;
    }
    this.commonService.showSnackbar(msg_);
    return of(null);
  }

  public onValidFromDateChange($event: MatDatepickerInputEvent<any, any>) {
    console.log('Date change event is : ', $event);
    const value = this.f['valid_from'].value;
    const errors = this.f['valid_from'].errors;
    console.log('Error value is : ', errors);
    console.log('form value : ', value);
    this.validToMinDate = moment(value).add(1, 'day').toDate();
    console.log('min valida date : ', this.validToMinDate);
  }

  public onDeleteClick() {
    const con = confirm('You want to delete the discount?');
    if (!con) return;
    this.commonService.showLoader();
    const { id } = this.data.vendor;
    const { id: discountId } = this.data.surcharge;
    const url = `/api/cc-backbone/abs/surcharge?vendor_id=${id}&discount_id=${discountId}`;
    this.req$ = this.authService
      .deleteData(url)
      .pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$
      .pipe(
        catchError((e) =>
          this.commonService.handleHttpError(
            e,
            'Error; Failed to delete discount!'
          )
        )
      )
      .pipe(filter((e) => e && true));
    this.req$ = this.req$.pipe(
      tap(() => this.commonService.showSnackbar('Discount has been deleted!'))
    );
  }
}
