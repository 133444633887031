<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            @if (vendorObj) {
            <div class="card mat-elevation-z1 border-0">
                <div class="card-header">
                    <p class="m-0 mb-1" style="text-transform: capitalize">
                        {{ vendorObj.name }}
                    </p>
                    <p>
                        <small>
                            {{
                            "Created on " +
                            (vendorObj.created_at._seconds * 1000 | date : "medium")
                            }}
                        </small>
                        @if (vendorObj.updated_at) {<small>{{
                            " | Last updated on
                            " +
                            (vendorObj.updated_at._seconds * 1000 | date : "medium")
                            }}</small>}
                    </p>
                </div>
                <div class="card-body" [formGroup]="form">
                    <div class="row g-3">
                        <div class="col-3">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Vendor name</mat-label>
                                <input formControlName="name" matInput placeholder="Vendor name">
                                <mat-icon matSuffix>lunch_dining</mat-icon>
                                <mat-hint>Max charector 100</mat-hint>
                                @if (f && f['name'].hasError('required')) {
                                <mat-error>Field required</mat-error>
                                }
                                @if (f && f['name'].hasError('maxlength')) {
                                <mat-error>Max length 100</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Threecx number</mat-label>
                                <input formControlName="threecx" matInput placeholder="Threecx number">
                                <mat-icon matSuffix>phone</mat-icon>
                                @if (f && f['threecx'].hasError('required')) {
                                <mat-error>Field required</mat-error>
                                }
                                @if (f && f['threecx'].hasError('pattern')) {
                                <mat-error>Invalid phone number!</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Whatsapp number</mat-label>
                                <input formControlName="whatsapp" matInput placeholder="Whatsapp number">
                                <mat-icon matSuffix>groups</mat-icon>
                                @if (f && f['whatsapp'].hasError('required')) {
                                <mat-error>Field required</mat-error>
                                }
                                @if (f && f['whatsapp'].hasError('pattern')) {
                                <mat-error>Invalid phone number!</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-3 d-flex align-items-center">
                            <mat-slide-toggle formControlName="is_disabled">Disabled</mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-end">
                    <button type="button" (click)="onSaveVendorClick()"
                        class="btn btn-sm btn-outline-success">Save</button>
                </div>
            </div>
            }
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card border-0 mat-elevation-z1">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap: 10px;"> <span>Branches</span>
                            @if (branchesObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{branchesObj.count+' Branches'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>{{'Listing out all branches of vendor'}}</small></p>
                        }
                    </div>
                    <button (click)="onAddBranchClick()" type="button" class="btn btn-sm btn-outline-success"
                        style="align-self: flex-start;">Add branch</button>
                </div>
                @if (branchesObj.displayBranches.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (branchesObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <p class="m-0">No branches found!</p>
                        @if (!branchesObj.clicked) {
                        <button (click)="getBranches()" type="button" class="btn btn-sm btn-outline-primary">Get
                            branches</button>
                        }
                        }

                    </div>
                </div>
                }@else {
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of branchesObj.displayBranches; track $index) {
                            <tr>
                                <td> {{$index+1}} </td>
                                <td> {{item.name}} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.is_disabled) {
                                    <span class="text-muted">Disabled</span>
                                    }@else {
                                    <span>Active</span>
                                    }
                                </td>
                                <td class="d-block">
                                    <p><button (click)="onAddBranchClick(item)" type="button"
                                            class="btn btn-sm btn-outline-primary w-100">Details</button></p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (this.branchesObj && this.branchesObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="this.branchesObj.count" [pageSize]="branchesObj.pageSize"
                        (page)="onChangeInBranchPaginator($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="card border-0 mat-elevation-z1">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap: 10px;"> <span>Menu Items</span>
                            @if (menuObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{menuObj.count+' Menu items'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>Listing out all menu items under the vendor <span
                                    style="font-style: italic;">{{' '+vendorObj.name}}</span></small>
                        </p>
                        }
                    </div>
                    <button (click)="onAddMenuItemClick()" type="button" class="btn btn-sm btn-outline-success"
                        style="align-self: flex-start;">Add menu item</button>
                </div>
                @if (menuObj.displayItems.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (menuObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <p class="m-0">No menu items found!</p>
                        <button (click)="getMenuItems()" type="button" class="btn btn-sm btn-outline-primary">Get
                            menu items</button>
                        }
                    </div>
                </div>
                }@else {
                <div class="card-body pb-0">
                    <table class="table order-table mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th class="text-center">Has options?</th>
                                <th>Unit price</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of menuObj.displayItems; track $index) {
                            <tr>
                                <td> {{ $index+1 }} </td>
                                <td> {{ item.name }} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.is_disabled) {
                                    <span class="text-muted">Disabled</span>
                                    }@else {
                                    <span>Active</span>
                                    }
                                </td>
                                <td class="text-center">
                                    {{ item.has_options ? 'Yes':'No' }}
                                </td>
                                <td>
                                    {{ item.price }}
                                </td>
                                <td class="d-block">
                                    <p><button (click)="onAddMenuItemClick(item)" type="button"
                                            class="btn btn-sm btn-outline-primary w-100">Details</button></p>
                                    <p><button (click)="onDeleteMenuClick(item)" type="button"
                                            class="btn btn-sm btn-outline-danger w-100">Delete</button></p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (this.menuObj && this.menuObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="this.menuObj.count" [pageSize]="menuObj.pageSize"
                        (page)="onChangeInMenuItemPaginator($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card border-0 mat-elevation-z1">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap: 10px;"> <span>Menu groups</span>
                            @if (menuGroupObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{menuGroupObj.count+' Menu groups'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>{{'Listing out all menu groups of vendor'}}</small></p>
                        }
                    </div>
                    <button (click)="onAddMenuGroupClick()" type="button" class="btn btn-sm btn-outline-success"
                        style="align-self: flex-start;">Add menu group</button>
                </div>
                @if (menuGroupObj.displayItems.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (menuGroupObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <p class="m-0">No menu groups found!</p>
                        @if (!menuGroupObj.clicked) {
                        <button (click)="getMenuGroups()" type="button" class="btn btn-sm btn-outline-primary">Get
                            menu groups</button>
                        }
                        }

                    </div>
                </div>
                }@else {
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of menuGroupObj.items; track $index) {
                            <tr>
                                <td> {{$index+1}} </td>
                                <td> {{item.name}} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.is_disabled) {
                                    <span class="text-muted">Disabled</span>
                                    }@else {
                                    <span>Active</span>
                                    }
                                </td>
                                <td class="d-block">
                                    <p><button (click)="onAddMenuGroupClick(item)" type="button"
                                            class="btn btn-sm btn-outline-primary w-100">Details</button></p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (menuGroupObj && menuGroupObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="menuGroupObj.count" [pageSize]="menuGroupObj.pageSize"
                        (page)="onChangeInMenuGroupPaginator($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="card border-0 mat-elevation-z1">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap:5px;"> <span>Menu category</span>
                            @if (menuCatObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{menuCatObj.count+' categories'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>Listing out all category under the vendor <span
                                    style="font-style: italic;">{{' '+vendorObj.name}}</span></small>
                        </p>
                        }
                    </div>
                    <button (click)="onAddMenuCatClick()" type="button" class="btn btn-sm btn-outline-success"
                        style="align-self: flex-start;">Add category</button>
                </div>
                @if (menuCatObj.displayItems.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (menuCatObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <p class="m-0">No category found!</p>
                        @if (!menuCatObj.clicked) {
                        <button (click)="getMenuCategory()" type="button" class="btn btn-sm btn-outline-primary">Get
                            categories</button>
                        }
                        }
                    </div>
                </div>
                }@else {
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of menuCatObj.displayItems; track $index) {
                            <tr>
                                <td> {{$index+1}} </td>
                                <td> {{item.name}} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.is_disabled) {
                                    <span class="text-muted">Disabled</span>
                                    }@else {
                                    <span>Active</span>
                                    }
                                </td>
                                <td class="d-block">
                                    <p><button (click)="onAddMenuCatClick(item)" type="button"
                                            class="btn btn-sm btn-outline-primary w-100">Details</button></p>
                                    <p><button (click)="onDeleteCatClick(item)" type="button"
                                            class="btn btn-sm btn-outline-danger w-100">Delete</button></p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (this.menuCatObj && this.menuCatObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="this.menuCatObj.count" [pageSize]="menuCatObj.pageSize"
                        (page)="onChangeInCatPaginatorMenuCat($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card border-0 mat-elevation-z1">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap: 5px;"> <span>Menu sub category</span>
                            @if (menuSubCatObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{menuSubCatObj.count+' sub
                                categories'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>Listing out all sub category items under the vendor <span
                                    style="font-style: italic;">{{' '+vendorObj.name}}</span></small>
                        </p>
                        }
                    </div>
                    <button (click)="onAddMenuSubCatClick()" type="button" class="btn btn-sm btn-outline-success"
                        style="align-self: flex-start;">Add sub category</button>
                </div>
                @if (menuSubCatObj.displayItems.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (menuSubCatObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <p class="m-0">No sub category found!</p>
                        @if (!menuSubCatObj.clicked) {
                        <button (click)="getMenuSubCategory()" type="button" class="btn btn-sm btn-outline-primary">Get
                            sub categories</button>
                        }
                        }
                    </div>
                </div>
                }@else {
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of menuSubCatObj.displayItems; track $index) {
                            <tr>
                                <td> {{$index+1}} </td>
                                <td> {{item.name}} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.is_disabled) {
                                    <span class="text-muted">Disabled</span>
                                    }@else {
                                    <span>Active</span>
                                    }
                                </td>
                                <td class="d-block">
                                    <p><button (click)="onAddMenuSubCatClick(item)" type="button"
                                            class="btn btn-sm btn-outline-primary w-100">Details</button></p>
                                    <p><button (click)="onDeleteSubCatClick(item)" type="button"
                                            class="btn btn-sm btn-outline-danger w-100">Delete</button></p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (this.menuSubCatObj && this.menuSubCatObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="this.menuSubCatObj.count" [pageSize]="menuSubCatObj.pageSize"
                        (page)="onChangeInSubCatPaginatorMenuCat($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="card border-0 mat-elevation-z1">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap: 5px;"> <span>Discounts</span>
                            @if (discountObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{discountObj.count+' Discounts'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>Listing out all discounts under the vendor <span
                                    style="font-style: italic;">{{'
                                    '+vendorObj.name}}</span></small>
                        </p>
                        }
                    </div>
                    <button (click)="onAddDiscountClick()" type="button" class="btn btn-sm btn-outline-success"
                        style="align-self: flex-start;">Add discount</button>
                </div>
                @if (discountObj.displayItems.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (discountObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <p class="m-0">No discount found!</p>
                        <button (click)="getDiscounts()" type="button" class="btn btn-sm btn-outline-primary">Get
                            discounts</button>
                        }
                    </div>
                </div>
                }@else {
                <div class="card-body pb-0">
                    <table class="table mb-0 order-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of discountObj.displayItems; track $index) {
                            <tr>
                                <td> {{ $index+1 }} </td>
                                <td> {{ item.name }} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.is_disabled) {
                                    <span class="text-muted">Disabled</span>
                                    }@else {
                                    <span>Active</span>
                                    }
                                </td>
                                <td>
                                    {{item.type=='per'?'Percentage':'Value'}}
                                </td>
                                <td>
                                    {{ item.value }}
                                </td>
                                <td class="d-block">
                                    <p><button (click)="onAddDiscountClick(item)" type="button"
                                            class="btn btn-sm btn-outline-primary w-100">Details</button></p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (discountObj && discountObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="this.discountObj.count" [pageSize]="discountObj.pageSize"
                        (page)="onChangeInDiscountPaginator($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card border-0 mat-elevation-z1">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap: 5px;"> <span>Surcharges</span>
                            @if (surchargeObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{surchargeObj.count+' Discounts'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>Listing out all surcharges under the vendor <span
                                    style="font-style: italic;">{{'
                                    '+vendorObj.name}}</span></small>
                        </p>
                        }
                    </div>
                    <button (click)="onAddSurchargeClick()" type="button" class="btn btn-sm btn-outline-success"
                        style="align-self: flex-start;">Add surcharge</button>
                </div>
                @if (surchargeObj.displayItems.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (surchargeObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <p class="m-0">No surcharge found!</p>
                        <button (click)="getSurcharges()" type="button" class="btn btn-sm btn-outline-primary">Get
                            surcharges</button>
                        }
                    </div>
                </div>
                }@else {
                <div class="card-body pb-0">
                    <table class="table mb-0 order-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of surchargeObj.displayItems; track $index) {
                            <tr>
                                <td> {{ $index+1 }} </td>
                                <td> {{ item.name }} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.is_disabled) {
                                    <span class="text-muted">Disabled</span>
                                    }@else {
                                    <span>Active</span>
                                    }
                                </td>
                                <td>
                                    {{item.type=='per'?'Percentage':'Value'}}
                                </td>
                                <td>
                                    {{ item.value }}
                                </td>
                                <td class="d-block">
                                    <p><button (click)="onAddSurchargeClick(item)" type="button"
                                            class="btn btn-sm btn-outline-primary w-100">Details</button></p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (surchargeObj && surchargeObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="this.surchargeObj.count" [pageSize]="surchargeObj.pageSize"
                        (page)="onChangeInSurchargePaginator($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="card border-0 mat-elevation-z1">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap: 5px;"> <span>Users</span>
                            @if (userObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{userObj.count+' Users'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>Listing out all users under the vendor <span
                                    style="font-style: italic;">{{'
                                    '+vendorObj.name}}</span></small>
                        </p>
                        }
                    </div>
                    <button (click)="onAddUserClick()" type="button" class="btn btn-sm btn-outline-success"
                        style="align-self: flex-start;">Add user</button>
                </div>
                @if (userObj.displayItems.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (userObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <p class="m-0">No users found!</p>
                        <button (click)="getUsers()" type="button" class="btn btn-sm btn-outline-primary">Get
                            users</button>
                        }
                    </div>
                </div>
                }@else {
                <div class="card-body pb-0">
                    <table class="table mb-0 order-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of userObj.displayItems; track $index) {
                            <tr>
                                <td> {{ $index+1 }} </td>
                                <td> {{ item.name }} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.is_disabled) {
                                    <span class="text-muted">Disabled</span>
                                    }@else {
                                    <span>Active</span>
                                    }
                                </td>
                                <td>
                                    {{item.email}}
                                </td>
                                <td>
                                    {{ item.role.label || '--' }}
                                </td>
                                <td class="d-block">
                                    <p><button (click)="onAddUserClick(item)" type="button"
                                            class="btn btn-sm btn-outline-primary w-100">Details</button></p>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (userObj && userObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="userObj.count" [pageSize]="userObj.pageSize"
                        (page)="onChangeInUserPaginator($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="card border-0 mat-elevation-z1 mb-5">
                <div class="card-header d-flex justify-content-between">
                    <div>
                        <p class="m-0 mb-1 d-flex align-items-center" style="gap: 10px;"> <span>Orders</span>
                            @if (orderObj.count > 0) {
                            <span class="badge rounded-pill bg-secondary">{{orderObj.count+' Orders'}}</span>
                            }
                        </p>
                        @if (vendorObj) {
                        <p class="m-0"><small>{{'Listing out all orders of vendor'}}</small></p>
                        }
                    </div>
                </div>
                <div class="card-header bg-white d-flex" style="gap: 3px;">
                    <div class="row w-100" [formGroup]="orderForm">
                        <div class="col-12 col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>Enter a date range</mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate formControlName="startDate" placeholder="Start date">
                                    <input matEndDate formControlName="endDate" placeholder="End date">
                                </mat-date-range-input>
                                <mat-hint>MM DD YYYY - MM DD YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="row">
                                <div class="col-12 col-md-3">
                                    <mat-form-field class="w-100">
                                        <mat-label>Processed</mat-label>
                                        <mat-select formControlName="status" multiple="true">
                                            @for (food of statuses; track food) {
                                            <mat-option [value]="food">{{food}}</mat-option>
                                            }
                                        </mat-select>
                                        <mat-hint>order is completed or in progress</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-4">
                                    <mat-form-field class="w-100">
                                        <mat-label>Branches</mat-label>
                                        <mat-select formControlName="branch" multiple="true">
                                            @for (food of branches; track food) {
                                            <mat-option [value]="food">{{food.name}}</mat-option>
                                            }
                                        </mat-select>
                                        <mat-hint>Available only active branches</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-5">
                                    <mat-form-field class="w-100">
                                        <mat-label>Order number</mat-label>
                                        <input matInput placeholder="Order number" formControlName="order_no">
                                        <mat-hint>If order number given, all other filter will be
                                            discarded</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-1 d-flex justify-content-center align-items-center">
                            <button (click)="getOrders()" type="button" class="btn btn-sm btn-outline-primary">Get
                                orders</button>
                        </div>
                    </div>
                </div>
                @if (orderObj.displayOrders.length==0) {
                <div style="min-height: 30em;" class="card-body d-flex justify-content-center align-items-center">
                    <div class="d-inline-flex flex-column justify-content-center align-items-center" style="gap:5px">
                        @if (orderObj.loading) {
                        <mat-spinner [diameter]="30"></mat-spinner>
                        }@else {
                        <h3 class="m-0">No orders found!</h3>
                        <span class="text-muted"> Clear the filters and try again! </span>
                        }

                    </div>
                </div>
                }@else {
                <div class="card-body pb-0">
                    <table class="table order-table mb-0">
                        <thead>
                            <tr>
                                <th>Order number</th>
                                <th>Branch</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th>Customer</th>
                                <th>Sub total</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of orderObj.displayOrders; track $index) {
                            <tr>
                                <td> {{ item.order_number }} </td>
                                <td> {{ item.branch_name||'--' }} </td>
                                <td> {{ item.created_at_as_date|date:'medium' }} </td>
                                <td>
                                    @if (item.status =='CANCELLED') {
                                    <span class="text-danger">{{item.status}}</span>
                                    }@else if(item.status =='DELIVERED') {
                                    <span class="text-success">{{item.status}}</span>
                                    }@else {
                                    <span class="text-info">{{item.status}}</span>
                                    }
                                </td>
                                <td>
                                    <div class="d-flex flex-column">
                                        <span>{{ item.customer_name }}</span>
                                        <small> {{ item.customer_phone }} </small>
                                    </div>
                                </td>
                                <td>{{ item.subTotal }}</td>
                                <td>{{ item.total }}</td>
                                <td> <button type="button" (click)="onOrderDetailsClick(item)"
                                        class="btn btn-sm btn-outline-primary">Details</button> </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                @if (this.orderObj && this.orderObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center">
                    <mat-paginator [length]="this.orderObj.count" [pageSize]="orderObj.pageSize"
                        (page)="onChangeInOrderPaginator($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
    </div>
</div>

@if (req$|async) {}