export const FirebaseCollection = {
  USERS: 'users',
  ROLES: 'roles',
  VENDORS: 'vendors',
  DELIVERY_AREAS: 'delivery_areas',
  BRANCHES: 'branches',
  VENDOR_REFS: 'vendor_refs',
  CUSTOMERS: 'customers',
  ORDERS: 'orders',
};
