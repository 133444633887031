import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonService } from '../../services/common.service';
import { signInWithEmailAndPassword } from '@angular/fire/auth';
import { Auth } from '@angular/fire/auth';
import { Title } from '@angular/platform-browser';

import { MatDialog } from '@angular/material/dialog';
import { DialogForgotPassword } from './dialog-forgot-password/dialog-forgot-password';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [],
})
export class LoginComponent {
  public readonly form: FormGroup;
  private readonly auth: Auth = inject(Auth);
  private readonly commonService: CommonService = inject(CommonService);
  private readonly titleService: Title = inject(Title);
  private readonly router: Router = inject(Router);

  public pwdIcon: string = 'visibility';
  public pwdType: string = 'password';

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog
  ) {
    this.titleService.setTitle('Login');
    this.form = this._buildForm();
  }

  private _buildForm() {
    let email = '';
    let pwd = '';
    if (environment.connectToEmulator) {
      email = 'rafiedacheri@gmail.com';
      pwd = 'home@123';
    }
    const form = this.fb.group({
      email: [email, [Validators.required, Validators.email]],
      pwd: [pwd, [Validators.required]],
    });

    return form;
  }

  public get f() {
    return this.form.controls;
  }

  public onSignInClick() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      const msg = 'Error; Invalid inputs';
      this.commonService.showSnackbar(msg);
      return;
    }
    const email = this.f['email'].value;
    const pwd = this.f['pwd'].value;
    this.commonService.showLoader();
    signInWithEmailAndPassword(this.auth, email, pwd)
      .then((_) => {
        // -- user logged in
        this.router.navigate(['/vendors']);
      })
      .catch((error) => {
        console.log('Error on sign in : ', error);
        const code = error.code;
        console.log('Error code is : ', code);
        let msg = 'Error; Failed to login';
        if (code == 'auth/invalid-credential') {
          msg = 'Error; Invalid email or password!';
          this.f['pwd'].setErrors({ invalid: true });
        }
        this.commonService.showSnackbar(msg);
      })
      .finally(() => this.commonService.hideLoader());
  }

  public onPwdIconClick() {
    const isPwd = this.pwdType == 'password';
    if (isPwd) {
      this.pwdType = 'text';
      this.pwdIcon = 'visibility_off';
    } else {
      this.pwdType = 'password';
      this.pwdIcon = 'visibility';
    }
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(DialogForgotPassword, {
      width: '35vw',
      data: { email: this.f['email'].value },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
