<h2 mat-dialog-title>Add vendor</h2>
<mat-dialog-content>
    <br>
    <div class="row" [formGroup]="form">
        <div class="col-5">
            <mat-form-field class="w-100">
                <mat-label>Vendor name</mat-label>
                <input formControlName="name" matInput type="text" placeholder="vendor name">
                @if (f && f['name'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['name'].hasError('maxlength')) {
                <mat-error>Max name length is 100</mat-error>
                }
            </mat-form-field>
        </div>
        <div class="col-2 d-flex align-items-center">
            <mat-slide-toggle formControlName="is_disabled">Disabled?</mat-slide-toggle>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card mat-elevation-z1 border-0">
                <div class="card-header">
                    <p class="m-0">Phone numbers</p>
                </div>
                <div class="card-body" [formGroup]="form">
                    <div class="row">
                        <div class="col-3">
                            <mat-form-field class="w-100">
                                <mat-label>Threecx</mat-label>
                                <input formControlName="threecx" matInput type="text" placeholder="Threecx number">
                                @if (f && f['threecx'].hasError('required')) {
                                <mat-error>Field required</mat-error>
                                }
                                @if (f && f['threecx'].hasError('pattern')) {
                                <mat-error>Invalid number!</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="w-100">
                                <mat-label>Whatsapp</mat-label>
                                <input formControlName="whatsapp" matInput type="text" placeholder="Whatsapp number">
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="w-100">
                                <mat-label>Landline</mat-label>
                                <input formControlName="normal" matInput type="text" placeholder="Landline number">
                                @if (f && f['normal'].hasError('required')) {
                                <mat-error>Field required</mat-error>
                                }
                                @if (f && f['normal'].hasError('pattern')) {
                                <mat-error>Invalid number!</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="w-100">
                                <mat-label>Mobile</mat-label>
                                <input formControlName="mobile" matInput type="text" placeholder="Mobile number">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onNoClick()">Close</button>
    <button mat-button type="button" (click)="onSaveClick()">Save</button>
</mat-dialog-actions>

@if (req$|async) {}