import { Component, inject } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {
  private readonly commonService: CommonService = inject(CommonService);
  private readonly titleService: Title = inject(Title);
  constructor() {
    this.titleService.setTitle('not-found');
    this.commonService.makePageLoaded();
  }
}
