import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly isLoading_ = new BehaviorSubject<boolean>(false);
  // Observable stream for components to subscribe
  public readonly isLoading$ = this.isLoading_.asObservable();

  private readonly isPageLoaded_ = new Subject<boolean>();
  // Observable stream for components to subscribe
  public readonly isPageLoaded$ = this.isPageLoaded_.asObservable();

  constructor(private readonly snackBar: MatSnackBar) {}

  public showLoader() {
    setTimeout(() => this.isLoading_.next(true));
  }

  public hideLoader() {
    setTimeout(() => this.isLoading_.next(false));
  }

  public makePageLoaded() {
    this.isPageLoaded_.next(true);
  }

  public showSnackbar(message: string, btn: string = 'close') {
    this.snackBar.open(message, btn, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 5 * 1000,
    });
  }

  public handleHttpError(e: any, msg_: string) {
    let msg: string = msg_;
    if (e instanceof HttpErrorResponse) {
      msg = e.error.msg || msg_;
    }
    this.showSnackbar(msg);
    return of(null);
  }
}
