<div class="container-fluid">
    <div class="row">
        <div class="d-none d-md-flex col-md-3"></div>
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="m-0">Vendors</p>
                            <h5 class="m-0 text-muted">Listing all vendors</h5>
                        </div>
                        <div>
                            <button (click)="onAddVendorClick()" type="button"
                                class="btn btn-sm btn-outline-success">Add vendor</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    @if (vendors.length == 0) {
                    <div class="d-flex w-100 justify-content-center align-items-center" style="min-height: 100px;">
                        <h3>No vendors found</h3>
                    </div>
                    }@else {
                    <div class="d-flex justify-content-end">
                        <div class="w-100 d-flex justify-content-end">
                            <input type="text" class="form-control" style="max-width: 30%;"
                                placeholder="Search for vendor">
                        </div>
                    </div>
                    <hr>
                    <table class="table item-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th class="text-center">Name</th>
                                <th class="text-end"></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of vendors; track $index) {
                            <tr>
                                <td>{{$index+1}}</td>
                                <td class="text-center">{{item.name}}</td>
                                <td class="text-end">
                                    <div class="d-flex flex-column justify-content-center">
                                        <p><button (click)="onVendorDetailsClick(item)" type="button"
                                                style="max-width: 10em;"
                                                class="btn btn-sm btn-outline-info w-100">Details</button>
                                        </p>
                                        <p><button type="button" class="w-100 btn btn-sm" style="max-width: 10em;"
                                                [ngClass]=" item.is_disabled?'btn-outline-success' :'btn-outline-danger'">
                                                {{
                                                item.is_disabled? 'Enable':'Disable' }} </button>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                    }
                </div>
                <div class="card-footer d-flex justify-content-center">
                    <p class="m-0">Load more</p>
                </div>
            </div>
        </div>
    </div>
</div>
@if (this.req$|async) {}