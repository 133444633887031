<h2 mat-dialog-title> {{data.title}} </h2>
<mat-dialog-content>
    @if (data.type == 'cat') {
    <br>
    <div class="row" [formGroup]="form">
        <div class="col-8">
            <mat-form-field class="w-100">
                <mat-label>Category name</mat-label>
                <input formControlName="name" matInput type="text" placeholder="category name">
                @if (f && f['name'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['name'].hasError('maxlength')) {
                <mat-error>Max name length is 100</mat-error>
                }
            </mat-form-field>
        </div>
        <div class="col-2 d-flex align-items-center">
            <mat-slide-toggle formControlName="is_disabled">Disabled?</mat-slide-toggle>
        </div>
    </div>
    <br>
    }@else if(data.type == 'sub_cat'){
    <br>
    <div class="row" [formGroup]="form">
        <div class="col-5">
            <mat-form-field class="w-100">
                <mat-label>sub category name</mat-label>
                <input formControlName="name" matInput type="text" placeholder="sub category name">
                @if (f && f['name'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['name'].hasError('maxlength')) {
                <mat-error>Max name length is 100</mat-error>
                }
            </mat-form-field>
        </div>
        <div class="col-5">
            <mat-form-field class="w-100">
                <mat-label>Category</mat-label>
                <mat-select formControlName="cat">
                    @for (food of categories; track food) {
                    <mat-option [value]="food.id">{{food.name}}</mat-option>
                    }
                </mat-select>
                @if (this.f && this.f['cat'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
            </mat-form-field>
        </div>
        <div class="col-2 d-flex align-items-center">
            <mat-slide-toggle formControlName="is_disabled">Disabled?</mat-slide-toggle>
        </div>
    </div>
    <br>
    }@else if(data.type == 'menu_group'){
    <br>
    <div class="row" [formGroup]="form">
        <div class="col-8">
            <mat-form-field class="w-100">
                <mat-label>menu group name</mat-label>
                <input formControlName="name" matInput type="text" placeholder="Please enter menu group name">
                @if (f && f['name'].hasError('required')) {
                <mat-error>Field required</mat-error>
                }
                @if (f && f['name'].hasError('maxlength')) {
                <mat-error>Max name length is 100</mat-error>
                }
            </mat-form-field>
        </div>
        <div class="col-2 d-flex align-items-center">
            <mat-slide-toggle formControlName="is_disabled">Disabled?</mat-slide-toggle>
        </div>
    </div>
    <br>
    }
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onNoClick()">Close</button>
    <button mat-button type="button" (click)="onSaveClick()">Save</button>
</mat-dialog-actions>

@if (req$|async) {}