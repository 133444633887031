import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export const NumberPatterns = {
  THREECX_PHONE: /^[1-9][0-9]{7}$/,
  LANDLINE_PHONE: /^[345678][0-9]{7}$/,
  WHOLE_NUMBER: /^\d+$/,
};

export const FormConstants = {
  MAX_CHAR: 100,
};

export function validNameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null; // Don't validate empty value (use required validator for that)
    }

    // Regular expression to allow alphanumeric characters, spaces, underscores, and dashes
    const validPattern = /^[a-zA-Z0-9 _-]+$/;

    // Check if the value matches the pattern and is not only spaces
    if (!validPattern.test(value) || value.trim().length === 0) {
      return { invalidName: true };
    }

    return null;
  };
}

export function validPriceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value;    
    if (!value) {
      return null; // Don't validate empty value (use required validator for that)
    }

    if (typeof value == 'string') {
      value = value.trim();
      if (!value) {
        return { required: true };
      }
    } else {
      return { pattern: true };
    }

    const numberValue = Number(value);
    console.log('number value: ', numberValue);
    if (isNaN(numberValue)) {
      return { pattern: true };
    } else {
      if (numberValue < 0) {
        return { invalidValue: true };
      }
    }

    return null;
  };
}
