import { Component, OnInit, inject } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../services/auth.service';
import {
  catchError,
  filter,
  finalize,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddBranches } from './dialog-add-branch/dialog-add-branch';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { DialogOrderDetails } from './dialog-order-details/dialog-order-details';
import { MatIconModule } from '@angular/material/icon';
import { NumberPatterns } from '../../constants/patterns';
import { Title } from '@angular/platform-browser';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DialogAddMenuItems } from './dialog-add-menu-items/dialog-add-menu-items';
import { DialogAddMenuComponent } from './dialog-add-menu-components/dialog-add-menu-components';
import { DialogAddDiscount } from './dialog-add-discount/dialog-add-discount';
import { DialogAddSurcharge } from './dialog-add-surcharge/dialog-add-surcharge';
import { DialogAddUser } from './dialog-add-user/dialog-add-user';

@Component({
  selector: 'app-vendor-details',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatProgressSpinner,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
  ],
  templateUrl: './vendor-details.component.html',
  styleUrl: './vendor-details.component.scss',
  providers: [],
})
export class VendorDetailsComponent implements OnInit {
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly titleService: Title = inject(Title);
  public req$: Observable<any> = of([]);
  public branches: any[] = [];
  public vendorObj: any = null;
  public form: FormGroup;

  public branchesObj: any = {
    branches: [],
    displayBranches: [],
    loading: false,
    clicked: false,
    count: 0,
    pageSize: 10,
  };

  public menuObj: any = {
    items: [],
    displayItems: [],
    count: 0,
    pageSize: 10,
  };

  public orderObj: any = {
    orders: [],
    displayOrders: [],
    loading: false,
    clicked: false,
    count: 0,
    pageSize: 10,
    filters: {},
  };

  public menuGroupObj: any = {
    items: [],
    displayItems: [],
    loading: false,
    clicked: false,
    count: 0,
    pageSize: 10,
    filters: {},
  };

  public menuCatObj: any = {
    items: [],
    displayItems: [],
    loading: false,
    clicked: false,
    count: 0,
    pageSize: 10,
    filters: {},
  };

  public menuSubCatObj: any = {
    items: [],
    displayItems: [],
    loading: false,
    clicked: false,
    count: 0,
    pageSize: 10,
    filters: {},
  };

  public discountObj: any = {
    items: [],
    displayItems: [],
    count: 0,
    pageSize: 10,
  };

  public surchargeObj: any = {
    items: [],
    displayItems: [],
    count: 0,
    pageSize: 10,
  };

  public userObj: any = {
    items: [],
    displayItems: [],
    count: 0,
    pageSize: 10,
  };

  public categoriesRefArray: any[] = []; // To avoid repetative read

  public readonly orderForm: FormGroup;

  public readonly statuses: string[] = ['Completed', 'Not Completed'];

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog
  ) {
    this.form = this._buildForm();
    this.orderForm = this._buildOrderForm();
  }

  ngOnInit(): void {
    this.commonService.showLoader();
    this.req$ = this.activatedRoute.paramMap.pipe(
      take(1),
      switchMap((x) => {
        const vendor_id = x.get('vendor_id') || '';
        this.titleService.setTitle('Vendor details - ' + vendor_id);
        return this._getVendorDetails(vendor_id);
      })
    );
  }

  private _getVendorDetails(vendor_id: string) {
    const url = '/api/cc-backbone/vendors/abs?vendor_id=' + vendor_id;
    return this.authService.getData(url).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(e, 'Error; Failed to get vendors!')
      ),
      filter((e: any) => e && true),
      map((d: any) => d.data),
      tap((d: any) => {
        const { vendor, branches } = d;
        this.vendorObj = vendor;
        this.branches = branches;
        console.log('Branches : ', this.branches);
        this.form = this._buildForm();
      })
    );
  }

  private _buildForm() {
    let form = this.fb.group({
      name: ['', []],
      whatsapp: ['', [Validators.pattern(NumberPatterns.THREECX_PHONE)]],
      threecx: [
        '',
        [Validators.required, Validators.pattern(NumberPatterns.THREECX_PHONE)],
      ],
      is_disabled: [false],
    });
    console.log('vendor obj : ', this.vendorObj);
    if (this.vendorObj) {
      const { name, phone, is_disabled } = this.vendorObj;
      const { threecx, whatsapp } = phone || {};
      form = this.fb.group({
        name: [name, [Validators.required]],
        whatsapp: [
          whatsapp,
          [Validators.pattern(NumberPatterns.THREECX_PHONE)],
        ],
        threecx: [
          threecx,
          [
            Validators.required,
            Validators.pattern(NumberPatterns.THREECX_PHONE),
          ],
        ],
        is_disabled: [is_disabled],
      });
    }
    return form;
  }

  private _buildOrderForm() {
    const form = this.fb.group({
      startDate: [''],
      endDate: [''],
      branch: [[]],
      status: [[]],
      order_no: [''],
    });
    return form;
  }

  public getBranches() {
    if (this.branchesObj.branches.length == 0 && this.branchesObj.clicked) {
      this.commonService.showSnackbar('There is no branches to load');
      return;
    }

    this.branchesObj.loading = true;
    const { id } = this.vendorObj;
    const url = '/api/cc-backbone/branches/abs?vendor_id=' + id;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.branchesObj.loading = false;
        this.branchesObj.clicked = true;
      }),
      map((d: any) => d.data),
      tap((x: any) => {
        const { branches, count, limit } = x;
        this.branchesObj.branches = branches;
        this.branchesObj.displayBranches = branches;
        this.branchesObj.count = count;
        this.branchesObj.pageSize = limit || 10;
      })
    );
  }

  public onAddBranchClick(branch: any = null) {
    const dialogRef = this.dialog.open(DialogAddBranches, {
      width: '55vw',
      data: { vendor: this.vendorObj, branch },
    });
    dialogRef
      .afterClosed()
      .pipe(filter((d) => d && typeof d == 'object'))
      .subscribe({
        next: (d) => {
          console.log('Result data : ', d);
          const { id } = d;
          if (!this.branchesObj.branches) this.branchesObj.branches = [];
          if (this.branchesObj.branches.length == 0) {
            this.getBranches();
            return;
          }
          const objIndex = this.branchesObj.branches.findIndex(
            (e1: any) => e1.id == id
          );
          if (objIndex == -1) {
            this.branchesObj.branches.push(d);
          } else {
            const { created_at_as_date, created_at } =
              this.branchesObj.branches[objIndex];
            this.branchesObj.branches[objIndex] = {
              ...d,
              created_at_as_date,
              created_at,
            };
          }
        },
      });
  }

  public onChangeInBranchPaginator($event: PageEvent) {
    console.log('Page event clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { branches = [] } = this.branchesObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayBranches = branches.slice(startIndex, endIndex);
    console.log('displayBranches : ', displayBranches);
    if (displayBranches.length) {
      this.branchesObj.displayBranches = displayBranches;
      return;
    }
    const length = branches.length;
    const lastElement = branches[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/branches/abs?vendor_id=${id}&start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.branchesObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((x: any) => {
        const { branches } = x;
        this.branchesObj.branches = this.branchesObj.branches.concat(branches);
        this.branchesObj.branches = this.branchesObj.branches.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.branchesObj.displayBranches = branches;
      })
    );
  }

  public onDeleteMenuClick(item: any) {
    console.log('Item is : ', item);
    const { name, id, shardingId } = item;
    const con = confirm(`Are you sure you want to delete ${name}?`);
    if (!con) return;
    this.commonService.showLoader();
    const url = '/api/cc-backbone/menu-item/abs/delete';
    const params = {
      id,
      shardingId,
      vendor_id: this.vendorObj.id,
    };
    this.req$ = this.authService.postData(url, params).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          'Error; Failed to delete menu item!'
        )
      ),
      filter((s: any) => !!s),
      tap((d) => {
        console.log('data after CRUD of menu item : ', d);
        const { msg } = d;
        this.commonService.showSnackbar(
          msg || 'Menu item has been deleted successfully'
        );
        this.menuObj.items = this.menuObj.items.filter(
          (e1: any) => e1.id != id
        );
        this.menuObj.displayItems = this.menuObj.displayItems.filter(
          (e1: any) => e1.id != id
        );
        this.menuObj.count = this.menuObj.count - 1;
        if (this.menuObj.count <= 0) {
          this.menuObj.count = 0;
        }
      })
    );
  }

  public getOrders() {
    const { id } = this.vendorObj;
    const start_date = this.orderForm.controls['startDate'].value;
    const end_date = this.orderForm.controls['endDate'].value;
    let branches: any[] = this.orderForm.controls['branch'].value;
    branches = branches.map((e1) => e1.id);
    const is_processed = this.orderForm.controls['status'].value;
    const order_no = this.orderForm.controls['order_no'].value;
    console.log('Is processed : ', is_processed);
    const url = `/api/cc-backbone/orders`;
    const params = {
      vendor_id: id,
      start_date,
      end_date,
      branches,
      is_processed,
      order_no: Number(order_no) || 0,
    };
    if (Number(order_no)) {
      const { orders = [] } = this.orderObj;
      const displayOrders = orders.filter(
        (e1: any) => e1.order_number == order_no
      );
      if (displayOrders.length > 0) {
        this.orderObj.displayOrders = displayOrders;
        return;
      }
    }
    console.log('order obj : ', this.orderObj);
    const {
      start_date: old_start_date = '',
      end_date: old_end_date = '',
      is_processed: old_is_processed = [],
      order_no: old_order_no = 0,
      branches: old_branches = [],
    } = this.orderObj.filters;
    console.log('old branches before : ', old_branches);
    const old_branches_as_string = Array.isArray(old_branches)
      ? old_branches.sort().join()
      : '';
    console.log('old branches after join : ', old_branches);
    console.log('old is processed before : ', old_is_processed);
    const old_is_processed_as_string = Array.from(old_is_processed)
      ? old_is_processed.sort().join()
      : '';
    console.log('old is processed as string after : ', old_is_processed);
    const newBranchesString = branches.sort().join();
    console.log('new branch string : ', newBranchesString);
    const is_processedString = is_processed.sort().join();
    console.log('is_processedString string : ', is_processedString);
    console.log('old_is_processed string as string : ', old_is_processed);
    if (
      old_start_date == start_date &&
      old_end_date == end_date &&
      old_is_processed_as_string == is_processedString &&
      old_order_no == order_no &&
      old_branches_as_string == newBranchesString &&
      this.orderObj.orders.length > 0
    ) {
      console.log('Filters are matching');
      return;
    }
    this.orderObj.loading = true;
    this.req$ = this.authService.postData(url, params).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.orderObj.loading = false;
        this.orderObj.clicked = true;
      }),
      catchError((e) =>
        this.commonService.handleHttpError(e, 'Error; Failed to get orders!')
      ),
      filter((e: any) => e && true),
      map((d: any) => d.data),
      tap((d: any) => {
        const { orders = [], count = 0, limit = 10, filters = {} } = d;
        this.orderObj.orders = orders;
        this.orderObj.displayOrders = orders;
        this.orderObj.count = count;
        this.orderObj.pageSize = limit;
        this.orderObj.filters = filters;
      })
    );
  }

  public onChangeInOrderPaginator($event: PageEvent) {
    console.log('Page event of branches clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { orders = [], displayOrders = [] } = this.orderObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('Start index : ', startIndex);
    console.log('End index : ', endIndex);
    const displayBranches = orders.slice(startIndex, endIndex);
    console.log('display Orders : ', displayBranches);
    if (displayBranches.length > 0) {
      this.orderObj.displayOrders = displayBranches;
      return;
    }
    const length = displayOrders.length;
    const lastElement = displayOrders[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/orders`;
    const { filters } = this.orderObj;
    const { start_date, end_date, is_processed, branches } = filters;
    const params = {
      vendor_id: id,
      start_date,
      end_date,
      branches,
      is_processed,
      order_no: 0,
      start_after,
    };
    this.req$ = this.authService.postData(url, params).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.branchesObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((x: any) => {
        const { orders } = x;
        console.log('orders from backbone : ', orders);
        this.orderObj.orders = this.orderObj.orders.concat(orders);
        this.orderObj.orders = this.orderObj.orders.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.orderObj.displayOrders = orders;
      })
    );
  }

  public onOrderDetailsClick(order: any) {
    this.dialog.open(DialogOrderDetails, {
      data: { order, vendor: this.vendorObj },
      width: '80vw',
      panelClass: ['custom-dialog-0'],
      autoFocus: false,
    });
  }

  public onSaveVendorClick() {
    const keys = ['name', 'threecx', 'whatsapp', 'is_disabled'];
    let invalid = false;
    const params: any = {};
    for (const k of keys) {
      let value = this.form.controls[k].value;
      if (typeof value == 'string') {
        value = value.trim();
      } else if (typeof value == 'boolean') {
        value = value;
      } else {
        value = '';
      }
      if (k == 'name') {
        if (!value) {
          invalid = true;
          this.form.controls[k].setErrors({ required: true });
        }
      }

      if (k == 'threecx') {
        if (value) {
          const noValue = Number(value);
          const patternMatch = NumberPatterns.THREECX_PHONE.exec(value);
          if (isNaN(noValue)) {
            invalid = true;
            this.form.controls[k].setErrors({ pattern: true });
          } else if (!patternMatch) {
            invalid = true;
            this.form.controls[k].setErrors({ pattern: true });
          }
        } else {
          invalid = true;
          this.form.controls[k].setErrors({ required: true });
        }
      }

      params[k] = value;
    }
    if (invalid) {
      this.commonService.showSnackbar('Error; Invalid form values');
      return;
    }
    const url = '/api/cc-backbone/vendors';
    params['id'] = this.vendorObj ? this.vendorObj.id || null : null;
    console.log('Sending params : ', params);
    this.commonService.showLoader();
    this.req$ = this.authService.postData(url, params).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((e) =>
        this.commonService.handleHttpError(
          e,
          'Error; Failed to update vendors!'
        )
      ),
      filter((e: any) => e && true),
      map((d: any) => d.data),
      tap((d: any) => {
        const { vendor = {} } = d;
        const { phone, name, is_disabled } = vendor;
        this.vendorObj = { ...this.vendorObj, phone, name, is_disabled };
        this.form = this._buildForm();
        this.commonService.showSnackbar('vendor details has been updated!');
      })
    );
  }

  public get f() {
    return this.form.controls;
  }

  public onChangeInMenuItemPaginator($event: PageEvent) {
    console.log('Page event clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { items = [] } = this.menuObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayItems = items.slice(startIndex, endIndex);
    console.log('display menu category : ', displayItems);
    if (displayItems.length) {
      this.menuObj.displayItems = displayItems;
      return;
    }
    const length = items.length;
    const lastElement = items[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/menu-items/abs?vendor_id=${id}&start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.menuObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items } = d;
        this.menuObj.items = this.menuObj.items.concat(items);
        this.menuObj.items = this.menuObj.items.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.menuObj.displayItems = items;
      })
    );
  }

  public onAddMenuItemClick(item: any = null) {
    const dialogRef = this.dialog.open(DialogAddMenuItems, {
      width: '80vw',
      data: { vendor: this.vendorObj, menuItem: item },
      autoFocus: false,
      hasBackdrop: true,
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(filter((d) => d && typeof d == 'object'))
      .subscribe({
        next: (d) => {
          console.log('Dialog closed : ', d);
          if (d) {
            if (this.menuObj.items.length == 0) {
              this.getMenuItems();
            } else {
              this.getMenuItems(d.id);
            }
          }
        },
      });
  }

  public getMenuItems(menuId: string = '') {
    if (this.menuObj.items.length == 0 && this.menuObj.clicked) {
      this.commonService.showSnackbar('There is no menu items to load');
      return;
    }

    this.menuObj.loading = true;
    const { id } = this.vendorObj;
    const url = menuId
      ? `/api/cc-backbone/menu-items/abs?vendor_id=${id}&menu_id=${menuId}`
      : '/api/cc-backbone/menu-items/abs?vendor_id=' + id;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.menuObj.loading = false;
        this.menuObj.clicked = true;
      }),
      map((d: any) => d.data),
      tap((x: any) => {
        if (menuId) {
          let { items: serverItems } = x;
          const item = serverItems[0];
          if (item) {
            const { items, displayItems } = this.menuObj;
            const itemsIndex = items.findIndex((e: any) => e.id == item.id);
            const displayItemsIndex = displayItems.findIndex(
              (e: any) => e.id == item.id
            );
            if (itemsIndex > -1) {
              items[itemsIndex] = item;
            } else {
              items.push(item);
            }
            if (displayItemsIndex > -1) {
              displayItems[displayItemsIndex] = item;
            } else {
              displayItems.push(item);
            }
          }
        } else {
          const { items, count, limit } = x;
          this.menuObj.items = items;
          this.menuObj.displayItems = items;
          this.menuObj.count = count;
          this.menuObj.pageSize = limit || 10;
        }
      })
    );
  }

  public onAddMenuGroupClick(item: any = null) {
    const data = {
      title: 'Add Menu group',
      type: 'menu_group',
      item,
      okFn: (params: any) => {
        this.commonService.showLoader();
        const url = '/api/cc-backbone/menu-group/abs';
        const { dialogCloseFn, ...otherParams } = params;
        console.log('Other params : ', otherParams);
        const id: string = item ? item.id || null : null;
        const params1 = {
          ...otherParams,
          vendor_id: this.vendorObj.id,
          id,
        };
        this.req$ = this.authService.postData(url, params1).pipe(
          finalize(() => this.commonService.hideLoader()),
          filter((s: any) => !!s),
          tap((d) => {
            console.log('data after CRUD of menu group : ', d);
            const { msg, data } = d;
            if (
              this.menuGroupObj.items &&
              this.menuGroupObj.items.length == 0
            ) {
              this.getMenuGroups();
              dialogCloseFn();
              return;
            }
            this.commonService.showSnackbar(
              msg || 'Menu group has been ' + (item ? 'updated' : 'added')
            );
            const oldObjIndex = this.menuGroupObj.items.findIndex(
              (e1: any) => id && e1.id == id
            );
            if (oldObjIndex == -1) {
              this.menuGroupObj.items.push(data);
            } else {
              const { name, is_disabled, updated_at } = data;
              let oldData = this.menuGroupObj.items[oldObjIndex];
              oldData = { ...oldData, name, is_disabled, updated_at };
              this.menuGroupObj.items[oldObjIndex] = oldData;
            }
            dialogCloseFn();
          })
        );
      },
    };
    this._openDialog(data);
  }

  public getMenuGroups() {
    if (this.menuGroupObj.items.length == 0 && this.menuGroupObj.clicked) {
      this.commonService.showSnackbar('There is no menu groups to load');
      return;
    }

    this.menuGroupObj.loading = true;
    const { id } = this.vendorObj;
    const url = '/api/cc-backbone/menu-group/abs?vendor_id=' + id;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.menuGroupObj.loading = false;
        this.menuGroupObj.clicked = true;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items, count, limit } = d;
        this.menuGroupObj.items = items;
        this.menuGroupObj.displayItems = items;
        this.menuGroupObj.count = count;
        this.menuGroupObj.pageSize = limit || 10;
      })
    );
  }

  public onChangeInMenuGroupPaginator($event: PageEvent) {
    console.log('Page event clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { items = [] } = this.menuCatObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayItems = items.slice(startIndex, endIndex);
    console.log('display menu groups : ', displayItems);
    if (displayItems.length) {
      this.menuGroupObj.displayItems = displayItems;
      return;
    }
    const length = items.length;
    const lastElement = items[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/menu-group/abs?vendor_id=${id}&start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.menuGroupObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items } = d;
        this.menuGroupObj.items = this.menuGroupObj.items.concat(items);
        this.menuGroupObj.items = this.menuGroupObj.items.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.branchesObj.displayItems = items;
      })
    );
  }

  public getMenuCategory() {
    if (this.menuCatObj.items.length == 0 && this.menuCatObj.clicked) {
      this.commonService.showSnackbar('There is no category to load');
      return;
    }

    this.menuCatObj.loading = true;
    const { id } = this.vendorObj;
    const url = '/api/cc-backbone/category/abs?vendor_id=' + id;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.menuCatObj.loading = false;
        this.menuCatObj.clicked = true;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items, count, limit } = d;
        console.log('menu category loaded : ', d);
        this.menuCatObj.items = items;
        this.menuCatObj.displayItems = items;
        this.menuCatObj.count = count;
        this.menuCatObj.pageSize = limit || 10;
      })
    );
  }

  public onAddMenuCatClick(item: any = null) {
    const data = {
      title: 'Add category',
      type: 'cat',
      item,
      okFn: (params: any) => {
        this.commonService.showLoader();
        const url = '/api/cc-backbone/category/abs';
        const { dialogCloseFn, ...otherParams } = params;
        console.log('Other params : ', otherParams);
        const id: string = item ? item.id || null : null;
        const params1 = {
          ...otherParams,
          vendor_id: this.vendorObj.id,
          id,
        };
        this.req$ = this.authService.postData(url, params1).pipe(
          finalize(() => this.commonService.hideLoader()),
          filter((s: any) => s && true),
          tap((d) => {
            console.log('data after CRUD of cat : ', d);
            const { msg, data } = d;
            this.commonService.showSnackbar(
              msg || 'category has been ' + (item ? 'updated' : 'added')
            );
            if (this.menuCatObj.items && this.menuCatObj.items.length == 0) {
              this.getMenuCategory();
              dialogCloseFn();
              return;
            }
            const oldObjIndex = this.menuCatObj.items.findIndex(
              (e1: any) => id && e1.id == id
            );
            if (oldObjIndex == -1) {
              this.menuCatObj.items.push(data);
            } else {
              const { name, is_disabled, updated_at } = data;
              let oldData = this.menuCatObj.items[oldObjIndex];
              oldData = { ...oldData, name, is_disabled, updated_at };
              this.menuCatObj.items[oldObjIndex] = oldData;
            }

            if (this.menuCatObj.displayItems.length) {
              const oldObjIndex = this.menuCatObj.displayItems.findIndex(
                (e1: any) => id && e1.id == id
              );
              if (oldObjIndex == -1) {
                this.menuCatObj.displayItems.push(data);
              } else {
                const { name, is_disabled, updated_at } = data;
                let oldData = this.menuCatObj.displayItems[oldObjIndex];
                oldData = { ...oldData, name, is_disabled, updated_at };
                this.menuCatObj.displayItems[oldObjIndex] = oldData;
              }
            }
            dialogCloseFn();
          })
        );
      },
    };
    this._openDialog(data);
  }

  public onChangeInCatPaginatorMenuCat($event: PageEvent) {
    console.log('Page event clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { items = [] } = this.menuCatObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayItems = items.slice(startIndex, endIndex);
    console.log('display menu category : ', displayItems);
    if (displayItems.length) {
      this.menuCatObj.displayItems = displayItems;
      return;
    }
    const length = items.length;
    const lastElement = items[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/category/abs?vendor_id=${id}&start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.menuCatObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items } = d;
        this.menuCatObj.items = this.menuCatObj.items.concat(items);
        this.menuCatObj.items = this.menuCatObj.items.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.menuCatObj.displayItems = items;
      })
    );
  }

  public onAddMenuSubCatClick(item: any = null) {
    const data = {
      title: 'Add sub category',
      type: 'sub_cat',
      item,
      okFn: (params: any) => {
        this.commonService.showLoader();
        const url = '/api/cc-backbone/sub-cat/abs';
        const { dialogCloseFn, ...otherParams } = params;
        console.log('Other params : ', otherParams);
        const id: string = item ? item.id || null : null;
        const params1 = {
          ...otherParams,
          vendor_id: this.vendorObj.id,
          id,
        };
        this.req$ = this.authService.postData(url, params1).pipe(
          finalize(() => this.commonService.hideLoader()),
          filter((s: any) => s && true),
          tap((d) => {
            console.log('data after CRUD of sub cat : ', d);
            const { msg, data } = d;
            this.commonService.showSnackbar(
              msg || 'sub category has been ' + (item ? 'updated' : 'added')
            );
            if (
              this.menuSubCatObj.items &&
              this.menuSubCatObj.items.length == 0
            ) {
              this.getMenuSubCategory();
              dialogCloseFn();
              return;
            }
            const oldObjIndex = this.menuSubCatObj.items.findIndex(
              (e1: any) => id && e1.id == id
            );
            if (oldObjIndex == -1) {
              this.menuSubCatObj.items.push(data);
            } else {
              const { name, is_disabled, updated_at } = data;
              let oldData = this.menuSubCatObj.items[oldObjIndex];
              oldData = { ...oldData, name, is_disabled, updated_at };
              this.menuSubCatObj.items[oldObjIndex] = oldData;
            }
            dialogCloseFn();
          })
        );
      },
    };
    this._openDialog(data);
  }

  public onChangeInSubCatPaginatorMenuCat($event: PageEvent) {
    console.log('Page event clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { items = [] } = this.menuSubCatObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayItems = items.slice(startIndex, endIndex);
    console.log('display menu sub category : ', displayItems);
    if (displayItems.length) {
      this.menuSubCatObj.displayItems = displayItems;
      return;
    }
    const length = items.length;
    const lastElement = items[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/sub-cat/abs?vendor_id=${id}&start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.menuSubCatObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items } = d;
        this.menuSubCatObj.items = this.menuSubCatObj.items.concat(items);
        this.menuSubCatObj.items = this.menuSubCatObj.items.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.menuSubCatObj.displayItems = items;
      })
    );
  }

  public getMenuSubCategory() {
    if (this.menuSubCatObj.items.length == 0 && this.menuSubCatObj.clicked) {
      this.commonService.showSnackbar('There is no sub category to load');
      return;
    }

    this.menuSubCatObj.loading = true;
    const { id } = this.vendorObj;
    const url = '/api/cc-backbone/sub-cat/abs?vendor_id=' + id;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.menuSubCatObj.loading = false;
        this.menuSubCatObj.clicked = true;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items, count, limit } = d;
        this.menuSubCatObj.items = items;
        this.menuSubCatObj.displayItems = items;
        this.menuSubCatObj.count = count;
        this.menuSubCatObj.pageSize = limit || 10;
      })
    );
  }

  private _openDialog(data: any) {
    if (data.type == 'sub_cat') {
      if (this.categoriesRefArray.length == 0) {
        const { id } = this.vendorObj;
        const url = '/api/cc-backbone/category?vendor_id=' + id;
        this.req$ = this.authService.getData(url).pipe(
          catchError((e) =>
            this.commonService.handleHttpError(
              e,
              'Error; Failed to get categories'
            )
          ),
          filter((e: any) => e && true),
          map((d) => d.data),
          tap((d) => {
            const { items = [] } = d;
            this.categoriesRefArray = items;
            this.dialog.open(DialogAddMenuComponent, {
              data: { ...data, categories: this.categoriesRefArray },
              width: data.type == 'sub_cat' ? '50vw' : '40vw',
            });
          })
        );
      } else {
        this.dialog.open(DialogAddMenuComponent, {
          data: { ...data, categories: this.categoriesRefArray },
          width: data.type == 'sub_cat' ? '50vw' : '40vw',
        });
      }
    } else {
      this.dialog.open(DialogAddMenuComponent, {
        data: { ...data },
        width: data.type == 'sub_cat' ? '50vw' : '40vw',
      });
    }
  }

  public onAddDiscountClick(discount: any = null) {
    const dialogRef = this.dialog.open(DialogAddDiscount, {
      width: '80vw',
      data: { vendor: this.vendorObj, discount },
      autoFocus: false,
      hasBackdrop: true,
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(filter((d) => d && typeof d == 'object'))
      .subscribe({
        next: (d) => {
          console.log('Dialog closed : ', d);
          if (d) {
            if (this.discountObj.items.length == 0) {
              this.getDiscounts();
            } else {
              this.getDiscounts({ id: d.id });
            }
          }
        },
      });
  }

  public getDiscounts(params: any = {}) {
    const { id } = this.vendorObj;
    const { id: discountId } = params;
    let url = '/api/cc-backbone/abs/discount?vendor_id=' + id;
    if (discountId) {
      url += `&discountId=${discountId}`;
    }
    this.commonService.showLoader();
    this.req$ = this.authService
      .getData(url)
      .pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$
      .pipe(
        catchError((e) =>
          this.commonService.handleHttpError(
            e,
            'Error; Failed to get discounts!'
          )
        )
      )
      .pipe(filter((e1) => !!e1));
    this.req$ = this.req$.pipe(
      map((d) => d.data),
      tap((d) => {
        if (discountId) {
          let { items: serverItems } = d;
          const item = serverItems[0];
          if (item) {
            const { items, displayItems } = this.discountObj;
            const itemsIndex = items.findIndex((e: any) => e.id == item.id);
            const displayItemsIndex = displayItems.findIndex(
              (e: any) => e.id == item.id
            );
            if (itemsIndex > -1) {
              items[itemsIndex] = item;
            } else {
              items.push(item);
            }
            if (displayItemsIndex > -1) {
              displayItems[displayItemsIndex] = item;
            } else {
              displayItems.push(item);
            }
            this.discountObj = { ...this.discountObj, displayItems, items };
          }
        } else {
          const { items, count, limit } = d;
          console.log('discount loaded : ', d);
          this.discountObj.items = items;
          this.discountObj.displayItems = items;
          this.discountObj.count = count;
          this.discountObj.pageSize = limit || 10;
        }
      })
    );
  }

  public onChangeInDiscountPaginator($event: PageEvent) {
    console.log('Page event clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { items = [] } = this.discountObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayItems = items.slice(startIndex, endIndex);
    console.log('display discounts : ', displayItems);
    if (displayItems.length) {
      this.discountObj.displayItems = displayItems;
      return;
    }
    const length = items.length;
    const lastElement = items[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/abs/discount?vendor_id=${id}&start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.discountObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items } = d;
        this.discountObj.items = this.discountObj.items.concat(items);
        this.discountObj.items = this.discountObj.items.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.discountObj.displayItems = items;
      })
    );
  }

  public onAddSurchargeClick(discount: any = null) {
    const dialogRef = this.dialog.open(DialogAddSurcharge, {
      width: '80vw',
      data: { vendor: this.vendorObj, surcharge: discount },
      autoFocus: false,
      hasBackdrop: true,
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(filter((d) => d && typeof d == 'object'))
      .subscribe({
        next: (d) => {
          console.log('Dialog closed : ', d);
          if (d) {
            if (this.discountObj.items.length == 0) {
              this.getSurcharges();
            } else {
              this.getSurcharges({ id: d.id });
            }
          }
        },
      });
  }

  public getSurcharges(param: any = {}) {
    const { id } = this.vendorObj;
    let url = '/api/cc-backbone/abs/surcharge?vendor_id=' + id;
    const { id: surchargeId } = param;
    if (surchargeId) {
      url += `&surchargeId=${surchargeId}`;
    }
    this.commonService.showLoader();
    this.req$ = this.authService
      .getData(url)
      .pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$
      .pipe(
        catchError((e) =>
          this.commonService.handleHttpError(
            e,
            'Error; Failed to get surcharges!'
          )
        )
      )
      .pipe(filter((e1) => !!e1));
    this.req$ = this.req$.pipe(
      map((d) => d.data),
      tap((d) => {
        if (surchargeId) {
          let { items: serverItems } = d;
          const item = serverItems[0];
          if (item) {
            const { items, displayItems } = this.surchargeObj;
            const itemsIndex = items.findIndex((e: any) => e.id == item.id);
            const displayItemsIndex = displayItems.findIndex(
              (e: any) => e.id == item.id
            );
            if (itemsIndex > -1) {
              items[itemsIndex] = item;
            } else {
              items.push(item);
            }
            if (displayItemsIndex > -1) {
              displayItems[displayItemsIndex] = item;
            } else {
              displayItems.push(item);
            }
            this.surchargeObj = { ...this.surchargeObj, displayItems, items };
          }
        } else {
          const { items, count, limit } = d;
          console.log('surcharge loaded : ', d);
          this.surchargeObj.items = items;
          this.surchargeObj.displayItems = items;
          this.surchargeObj.count = count;
          this.surchargeObj.pageSize = limit || 10;
        }
      })
    );
  }

  /**
   * Handles pagination changes for the surcharge list.
   * This function updates the displayed surcharge items based on the current page and fetches more items if necessary.
   *
   * @param $event - The PageEvent object containing pagination information.
   * @param $event.pageIndex - The index of the current page.
   * @param $event.pageSize - The number of items per page.
   *
   * @returns void
   *
   * @remarks
   * If there are enough items in the current list to display on the requested page,
   * it updates the displayItems without making an API call.
   * If more items are needed, it fetches the next batch of items from the server.
   */
  public onChangeInSurchargePaginator($event: PageEvent) {
    console.log('Page event clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { items = [] } = this.surchargeObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayItems = items.slice(startIndex, endIndex);
    console.log('display discounts : ', displayItems);
    if (displayItems.length) {
      this.surchargeObj.displayItems = displayItems;
      return;
    }
    const length = items.length;
    const lastElement = items[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/abs/surcharge?vendor_id=${id}&start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.surchargeObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items } = d;
        this.surchargeObj.items = this.surchargeObj.items.concat(items);
        this.surchargeObj.items = this.surchargeObj.items.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.surchargeObj.displayItems = items;
      })
    );
  }

  public onAddUserClick(item: any = null) {
    const dialogRef = this.dialog.open(DialogAddUser, {
      width: '50vw',
      data: { vendor: this.vendorObj, user: item },
      autoFocus: false,
      hasBackdrop: true,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: (d) => {
        console.log('Data after dialog close : ', d);
        if (d && item) {
          const { name, is_disabled } = d;
          item.name = name;
          item.is_disabled = is_disabled;
        } else if (d) {
          if (this.userObj.items.length > 0) {
            this.userObj.items.unshift(d);
            this.userObj.displayItems.unshift(d);
          } else {
            this.getUsers();
          }
        }
      },
    });
  }

  public getUsers() {
    const { id } = this.vendorObj;
    const url = '/api/cc-backbone/vendor/users?vendor_id=' + id;
    this.commonService.showLoader();
    this.req$ = this.authService
      .getData(url)
      .pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$
      .pipe(
        catchError((e) =>
          this.commonService.handleHttpError(e, 'Error; Failed to get users!')
        )
      )
      .pipe(filter((e1) => !!e1));
    this.req$ = this.req$.pipe(
      map((d) => d.data),
      tap((d) => {
        const { items, count, limit } = d;
        console.log('users loaded : ', d);
        this.userObj.items = items;
        this.userObj.displayItems = items;
        this.userObj.count = count;
        this.userObj.pageSize = limit || 10;
      })
    );
  }

  public onChangeInUserPaginator($event: PageEvent) {
    console.log('Page event of users clicked : ', $event);
    const { pageIndex, pageSize } = $event;
    const { items = [] } = this.userObj;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    console.log('start index : ', startIndex);
    console.log('end index : ', endIndex);
    const displayItems = items.slice(startIndex, endIndex);
    console.log('display discounts : ', displayItems);
    if (displayItems.length) {
      this.userObj.displayItems = displayItems;
      return;
    }
    const length = items.length;
    const lastElement = items[length - 1];
    if (!lastElement) return;
    const { created_at } = lastElement;
    console.log('Created at : ', created_at);
    const start_after = created_at['_seconds'] || created_at['seconds'];
    const { id } = this.vendorObj;
    const url = `/api/cc-backbone/vendor/users?vendor_id=${id}&start_after=${start_after}`;
    this.req$ = this.authService.getData(url).pipe(
      finalize(() => {
        this.commonService.hideLoader();
        this.userObj.loading = false;
      }),
      map((d: any) => d.data),
      tap((d: any) => {
        const { items } = d;
        this.userObj.items = this.userObj.items.concat(items);
        this.userObj.items = this.userObj.items.filter(
          (e1: any, i: number, arr: any[]) =>
            arr.findIndex((e2) => e2.id == e1.id) == i
        );
        this.userObj.displayItems = items;
      })
    );
  }

  public onDeleteCatClick(item: any) {
    const { id, name } = item;
    console.log('Delete category clicked : ', item);
    const conf = confirm(`Are you sure you want to delete category ${name}?`);
    if (!conf) return;
    const url = `/api/cc-backbone/category/abs?vendor_id=${this.vendorObj.id}&id=${id}`;
    this.commonService.showLoader();
    this.req$ = this.authService
      .deleteData(url)
      .pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$
      .pipe(
        catchError((e) =>
          this.commonService.handleHttpError(
            e,
            'Error; Failed to delete category!'
          )
        )
      )
      .pipe(filter((e1) => !!e1));
    this.req$ = this.req$.pipe(
      tap((d) => {
        const { msg } = d;
        this.commonService.showSnackbar(msg || 'Category has been deleted');
        const index = this.menuCatObj.items.findIndex((e: any) => e.id == id);
        this.menuCatObj.items.splice(index, 1);
        this.menuCatObj.displayItems = this.menuCatObj.items;
      })
    );
  }

  public onDeleteSubCatClick(item: any) {
    const { id, name } = item;
    console.log('Delete category clicked : ', item);
    const conf = confirm(`Are you sure you want to delete category ${name}?`);
    if (!conf) return;
    const url = `/api/cc-backbone/sub-cat/abs?vendor_id=${this.vendorObj.id}&id=${id}`;
    this.commonService.showLoader();
    this.req$ = this.authService
      .deleteData(url)
      .pipe(finalize(() => this.commonService.hideLoader()));
    this.req$ = this.req$
      .pipe(
        catchError((e) =>
          this.commonService.handleHttpError(
            e,
            'Error; Failed to delete category!'
          )
        )
      )
      .pipe(filter((e1) => !!e1));
    this.req$ = this.req$.pipe(
      tap((d) => {
        const { msg } = d;
        this.commonService.showSnackbar(msg || 'Category has been deleted');
        const index = this.menuSubCatObj.items.findIndex(
          (e: any) => e.id == id
        );
        this.menuSubCatObj.items.splice(index, 1);
        this.menuSubCatObj.displayItems = this.menuCatObj.items;
      })
    );
  }
}
