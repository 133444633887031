@if((this.authService.isAuthenticated$ | async) && (commonService.isPageLoaded$ | async)){
<ng-container>
    <mat-toolbar style="position: fixed;top: 0;left: 0;right: 0;z-index: 999;background-color: #D7e3ff;">
        <button (click)="sidebarVisible=!sidebarVisible" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button>
        <span>{{titleString}}</span>
        <span class="example-spacer"></span>
        <button (click)="onLogoutClick($event)" mat-icon-button title="click here to logout">
            <mat-icon>logout</mat-icon>
        </button>
    </mat-toolbar>
    <hr class="border-0" style="margin-bottom: 70px;">
</ng-container>
}

<router-outlet (activate)='onActivate($event)'></router-outlet>
@if (commonService.isLoading$|async) {
<div class="spinner-div">
    <mat-spinner [diameter]="30"></mat-spinner>
</div>
}

@if (authService.authStatus$|async;as signedUser) {
<p-sidebar [(visible)]="sidebarVisible" [showCloseIcon]="false">
    <div class="d-flex w-100 justify-content-center">
        <mat-chip-set>
            <mat-chip>
                <img matChipAvatar [src]="avatarIcon" alt="Profile image" />
                {{ signedUser.user.name }}
            </mat-chip>
        </mat-chip-set>
    </div>
    <hr style="border:0;border-bottom: 1px dotted;">
    @for (item of sidebarMenuItems;track item;) {
    <div (click)="item.func()" class="d-flex align-items-center" style="gap: 10px;cursor: pointer;">
        <span class="material-icons"> {{item.icon}} </span>
        <span> {{item.label}} </span>
    </div>
    <hr>
    }
</p-sidebar>
}
<p-confirmDialog></p-confirmDialog>