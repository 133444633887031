import { Component, inject, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormControl,
  FormsModule,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Auth } from '@angular/fire/auth';
import { sendPasswordResetEmail } from '@angular/fire/auth';
import { CommonService } from '../../../services/common.service';

interface DialogData {
  email: string;
}

@Component({
  selector: 'dialog-forgot-password',
  templateUrl: 'dialog-forgot-password.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
  ],
})
export class DialogForgotPassword {
  public readonly form: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  private readonly auth: Auth = inject(Auth);
  constructor(
    private readonly dialogRef: MatDialogRef<DialogForgotPassword>,
    private readonly commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private readonly data: DialogData
  ) {
    this.form.setValue(this.data.email || '');
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onSubmitClick(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.commonService.showLoader();
    sendPasswordResetEmail(this.auth, this.form.value)
      .then(() => {
        this.commonService.showSnackbar(
          `Password reset email has send to the email ${this.form.value}`
        );
        this.dialogRef.close();
      })
      .catch((error) => {
        console.log('Error on pwd reset -- ', error);
        let msg = 'Error; Failed to send the email!';
        const code = error.code;
        if (code == 'auth/invalid-email' || code == 'auth/missing-email') {
          this.form.setErrors({ email: true });
          msg = 'Error; Invalid email';
        }
        this.commonService.showSnackbar(msg);
      })
      .finally(() => this.commonService.hideLoader());
  }
}
