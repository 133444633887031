<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
            <div class="card">
                <div class="card-header d-flex justify-content-between bg-white">
                    <div>
                        <p class="m-0"><strong>{{title}}</strong></p>
                        <div>
                            <small class="text-muted">{{subTitle}}</small>
                        </div>
                    </div>
                    <div>
                        <button (click)="onAddAgentClick()" type="button" class="btn btn-sm btn-outline-success"
                            style="align-self: flex-start;">Add agent</button>
                    </div>
                </div>
                <div class="card-body py-0">
                    <table class="table mb-0 mt-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (item of agentObj.displayItems; track $index) {
                            <tr>
                                <td> {{ $index+1 }} </td>
                                <td>
                                    <div class="d-flex flex-column">
                                        <span>{{ item.name }}</span>
                                        <small class="text-muted">{{ item.created_at_as_date|date:'medium' }}</small>
                                    </div>
                                </td>
                                <td> {{ item.email }} </td>
                                <td>
                                    <button (click)="onStatusUpdateClick(item)" [ngClass]=" item.is_disabled ? 'text-danger' : ' text-success'" mat-icon-button>
                                        @if(item.is_disabled) {
                                        <mat-icon>cancel</mat-icon>
                                        }@else {
                                        <mat-icon>check_circle</mat-icon>
                                        }
                                    </button>
                                </td>
                                <td> <button (click)="onAddAgentClick(item)" type="button"
                                        class="btn btn-sm btn-outline-primary w-100">Details</button> </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                @if (agentObj && agentObj.count > 0) {
                <div class="card-footer d-flex justify-content-center align-items-center bg-white">
                    <mat-paginator [length]="agentObj.count" [pageSize]="agentObj.pageSize"
                        (page)="onChangeInPaginator($event)">
                    </mat-paginator>
                </div>
                }
            </div>
        </div>
    </div>
</div>
@if (req$|async) {}